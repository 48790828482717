.doc-indicators{

  h3{
    font-family: "Montserrat-Regular";
    font-size: $medium !important;
    cursor: pointer;
    margin-bottom: $margin-sm;
    margin-top: 0;
    svg{
      position: relative;
      top:-1px;
      // width: 8px !important;
      margin-right: 3px;
    }
  }

  .title-indicator{
    font-size: $regular !important;
    text-transform: uppercase;
    font-family: "Montserrat-Medium";
    margin: $margin-xs 0;
  }

  .indicators-containers{
    margin-bottom: $margin-sm;
  }

  .indicators{
    margin-left: 13px;
    margin-bottom: $margin-sm;
  }



  label{
    color: $disabled-blue;
  }

  a,p,label,.tag{
    font-size: $small !important;
    text-align: left !important;
    word-break: break-word;
  }

  p{
    display: inline-block;
    margin: 0;
  }

  .tag{
    background-color: #FF7D67;
    display: inline-block;
    border-radius: 4px;
    padding: 3px 5px;
    margin: 0px 3px 3px 0px;
    color : $white;
    cursor: pointer;
    &.active{
      background-color: darken( $primary-color, 10% );
    }
  }

}
