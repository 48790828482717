.exploration:not(.expand){
  .container-help .container{
    width: 43%;
    margin: 0 auto 0 auto;
    padding-left: 10px;

    li{
      display: inline-block;
      width: calc(50% - 45px);
      &:nth-child(odd){
        margin: 10px $margin-sm 10px $margin-md;
      }
      &:nth-child(even){
        margin: 10px $margin-md 10px $margin-sm;
      }
    }
  }
}

.exploration.light .container-help{
  .text-content{
    box-shadow: inset 0em 0em 0em 2px $blue;

  }

  .image-preview{
    &:after{
      position: absolute;
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: $blue;
      bottom: 0;
    }
  }

  .item-help h3{
    color: $blue;
  }
  // .image-preview{
  //   border: solid 3px $blue;
  // }
}

.exploration:not(.light) .container-help{

  .text-content{
    width: 100%;
    background-color: white;
  }

  .item-help h3{
    color: white;
  }
  // .image-preview{
  //   border: solid 3px white;
  // }
}

.container-help{
  padding-top: calc(#{$margin-md} + 67px);
  padding-left: 0 !important;
  overflow-y: auto;
  h2{
    position: relative !important;
  }
  .container{
    width: 100%;
    box-sizing: border-box;
    padding: 0 $margin-md;
    margin: 0 auto;
    transition: all .4s ease-in-out;

    @media screen and (min-width: 600px){
      width: 85%;
      padding: 0 0;
    }

    @media screen and (min-width: $sm-screen){
      width: 75%;
    }

    @media screen and (min-width: $md-screen){
      width: 65%;
    }


  }
  .list-help{
    overflow: hidden;
    padding: 0;
    margin: 0;
    .item-help{
      position: relative;
      display: inline-block;
      width: calc(100% - 30px);
      transition: all .4s ease-in-out;
      cursor: pointer;
      margin: $margin-sm;

      @media screen and (min-width: 600px){
        width: calc(50% - 30px);
      }

      @include breakpoint(md) {
        width: calc(33.3% - 30px);
        margin: 0;
        &:nth-child(3n){
          margin: $margin-sm $margin-sm $margin-sm $margin-md;
        }
        &:nth-child(3n+1){
          margin: $margin-sm $margin-md $margin-sm $margin-sm;
        }
      }

      @include breakpoint(xl) {
        width: calc(25% - 35px);
        margin: $margin-sm;
        &:nth-child(3n),&:nth-child(3n+1){
          margin: $margin-sm;
        }
      }


      // &:nth-child(odd){
      //   margin: $margin-sm $margin-sm $margin-sm $margin-md;
      // }
      // &:nth-child(even){
      //   margin: $margin-sm $margin-md $margin-sm $margin-sm;
      // }

      .title-hover{
        position: absolute;
        z-index: 1;
        top: 30px;
        opacity: 0;
        color: $primary-color;
      }

      h3{
        opacity: 1;
      }

      a{
        text-decoration:none;
      }

      .title-hover,h3{
        transition: all .4s ease-in-out;
      }

    }

    .text-content{
      position: absolute;
      color : $text-color;
      top:100%;
      height: 100%;
      padding: $margin-sm;
      box-sizing: border-box;
      overflow-y: auto;
      background-color: white;
      transition: all .4s ease-in-out;
      p{
        transform: translateY(30px);
        transition: all .7s ease-in-out;
        margin-top: 0;
        font-size: $small;
      }
    }

    .image-preview{
      position: relative;
      padding-top: 51%;
      width: 100%;
      border: solid 3px white;
      box-sizing: border-box;
      overflow: hidden;

      img{
        position: absolute;
        font-size: 0;
        display:block;
        width: 100%;
        top:0;
        transition: all .4s ease-in-out;
      }
    }

    .item-help.hover{

      .title-hover{
        position: absolute;
        top:0;
        opacity: 1;
      }

      h3:not(.title-hover){
        opacity: 0;
        position: relative !important;
        transform: translateY(-60px);
      }

      .text-content{
        top: 0;
        p{
          transform: translateY(0px);
        }
      }

      .image-preview img{
        transform: translateY(-10px);
      }

    }

  }

}
