.noMatch{
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .container{
    display: flex;
    align-items: center;
    justify-content: center;

    h1{
      font-family: "MontSerrat-SemiBold";
      font-size: 100px;
      margin: 0;
    }
    margin-bottom: $margin-sm;
  }

  p{
    font-family: "MontSerrat-SemiBold";
    color : $blue;
    font-size: $medium;
    margin-bottom: $margin-md;
    max-width: 560px;
    text-align: center;
    width: 90%;
  }

  button{
    padding: $margin-xs $margin-sm;
    font-size: $regular;
  }

}
