@import "./document-indicators.scss";
@import "./tags.scss";

.navigation-referentiel {
  position: fixed;
  z-index: 104;
  left: 0;
  width: 100%;
  min-height: 40px;
  bottom: 0px;
  background-color: $light-grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;

  @media screen and (min-width: #{$sm-screen}) {
    left: 20px;
    width: calc(50% - 65px);
    bottom: 20px;
    background-color: transparent;
  }

  @media screen and (min-width: #{$md-screen}) {
    width: calc(45% - 65px);
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    @media screen and (max-width: #{$sm-screen}) {
      padding: $margin-xs;
    }
    &:hover p {
      text-decoration: none;
    }
    p {
      color: $primary-color;
      font-family: "Montserrat-SemiBold";
      padding: 0 4px;
      margin: 0;
    }
  }
}

.document-view {
  position: fixed;
  z-index: 104;
  top: $header-height;
  box-sizing: border-box;
  background-color: $light-grey;
  overflow: hidden;

  @media screen and (min-width: #{$sm-screen}) {
    top: calc(#{$header-height} + 20px);
    border-radius: 15px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
  }

  &:not(.referentiel) {
    left: 0px;
    width: 100%;
    bottom: 0px;

    @media screen and (min-width: #{$sm-screen}) {
      left: 30px;
      width: calc(100% - 60px);
      bottom: 20px;
    }

    @media screen and (min-width: #{$md-screen}) {
      width: calc(100% - #{$filter-width} - 60px);
      left: 50%;
      max-width: 960px;
      transform: translateX(-50%);
    }

    .back {
      margin-bottom: $margin-sm;
    }

    .take-away {
      border: solid 2px $primary-color;
      padding: $margin-sm $margin-sm $margin-sm $margin-sm;
      margin: $margin-xs 0 $margin-md 0;

      @media screen and (min-width: #{$md-screen}) {
        padding: $margin-sm $margin-md $margin-md $margin-md;
      }

      legend {
        font-family: "Montserrat-SemiBold";
        font-size: $title;
        color: $primary-color;
        padding: 0 $margin-xs;
      }

      p {
        line-height: 20px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  .back {
    @include close();
    .close {
      transform: scale(0.5);
    }
  }

  &.referentiel {
    left: 0;
    width: 100%;
    bottom: 48px;

    @media screen and (min-width: #{$sm-screen}) {
      left: 20px;
      width: calc(50% - 65px);
      bottom: 60px;
    }

    @media screen and (min-width: $md-screen) {
      width: calc(45% - 65px);
    }

    .btn-inspiring-contents {
      text-transform: uppercase !important;
      background-color: $primary-color;
      padding-right: $margin-sm;
    }

    .back {
      width: 75px;
      &:hover {
        text-decoration: underline;
        color: $text-color;
        .close:after,
        .close:before {
          background-color: $text-color;
        }
      }
    }

    .abstract {
      p {
        font-family: "Montserrat-SemiBold";
        line-height: 18px;
        color: $disabled-blue;
      }
    }

    .container-read-more {
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0px;
      height: 120px;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      .gradient {
        z-index: -1;
      }
    }

    .container-image {
      @media screen and (max-width: $sm-screen) {
        margin: 0 calc(-1 *#{$margin-sm});
      }
      //
      // img{
      //   width :
      // }
    }

    .ref-container-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $margin-sm;

      > span {
        display: flex;
        justify-content: space-between;
      }

      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: $margin-sm;
        text-transform: capitalize;

        &:last-child {
          @media screen and (max-width: $md-screen) {
            margin-left: $margin-xs;
          }
        }

        img {
          margin: 0 10px 0 0;
        }
      }
    }
  }

  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
    width: 100%;
    padding: $margin-sm;
    box-sizing: border-box;
    @media screen and (min-width: #{$sm-screen}) {
      padding: $margin-md;
    }
  }

  // right: 30px;

  .doc-content-left,
  .doc-content-right {
    vertical-align: top;
    display: inline-block;

    @media screen and (max-width: #{$sm-screen}) {
      width: 100% !important;
    }

    button:hover {
      text-decoration: underline;
    }

    h5 {
      position: relative;
      background-color: $light-grey;
      clear: both;
      font-size: $regular;
      margin: $margin-sm 0;
    }
  }

  .doc-content-left {
    width: 70%;

    &.twoColsLayout .slider-image img {
      width: 100%;
    }
  }

  .doc-content-right {
    padding: 0;
    box-sizing: border-box;
    width: 30%;

    @media screen and (min-width: #{$sm-screen}) {
      padding: 0 $margin-sm;
    }

    .toggle-tags {
      position: relative;
      overflow: hidden;
      padding-bottom: $margin-sm;
      div:nth-child(2) {
        height: 60px;
        overflow: hidden;
      }
      .gradient {
        position: absolute;
        z-index: 2;
        top: 0;
        height: 60px;
        width: 100%;
        background: linear-gradient(
          0deg,
          $light-grey 0%,
          $light-grey 20%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      button {
        // position: absolute;
      }
    }
  }

  .loader-doc {
    position: absolute;
    width: 30px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .back {
    display: flex;
    font-size: $regular;
    align-items: center;
    color: $primary-color;
    cursor: pointer;
    width: 100%;
    i {
      margin-right: 3px;
    }
  }

  .iframe {
    position: relative;
    padding-top: calc(100% / 1.77);
    margin: $margin-md 0;
    iframe {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto $margin-sm auto;
  }

  h1 {
    position: relative;
    font-family: "Montserrat-Regular";
    font-size: $title !important;
    color: $text-color;
    margin-top: 0;

    @media screen and (min-width: #{$sm-screen}) {
      font-size: $titleH1 !important;

      &.fixed {
        position: fixed;
        z-index: 2;
        top: calc(#{$header-height} + 40px + 20px);
        background-color: $light-grey;
        left: 30px;
        right: 30px;
        margin-left: 10px;
        padding: $margin-md $margin-md $margin-sm;
        padding-left: calc(#{$margin-md} - 10px);
        border-radius: 15px;

        @media screen and (min-width: #{$md-screen}) {
          top: 0px;
          width: calc(100% - 50px);
          left: 50%;
          right: 0px;
          max-width: 960px;
          transform: translateX(-50%);
        }

        .back {
          margin-bottom: $margin-sm;
        }
      }
    }
  }

  .content {
    .pictos {
      padding-bottom: $margin-xs;
      span {
        margin-right: $margin-xs;
      }
    }

    .article-info {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .collectby {
        display: flex;
        align-items: center;
        p {
          margin: 0;
          font-family: "Montserrat";
          color: $disabled-blue;
          font-size: 12px;
          margin-right: 5px;
        }

        .state {
          color: $primary-color;
          font-family: "Montserrat-SemiBold";
        }
      }

      button {
        background-color: $primary-color;
        padding-left: $margin-sm;
        padding-right: $margin-sm;
        margin: 0;
        text-transform: inherit;
        @media screen and (min-width: #{$sm-screen}) {
          display: none;
        }
      }
    }

    .abstract {
      display: block;
      margin-bottom: $margin-sm;

      &.twoColsLayout p {
        font-family: "Montserrat-SemiBold";
        font-size: $medium;
        line-height: calc(#{$medium} + 6px);
        color: $disabled-blue;
        text-align: left;
        &:first-child {
          margin-top: 0;
        }
      }

      p {
        line-height: 20px;
      }
    }

    .container-abstract {
      button {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .detail-fact {
      &.twoColsLayout {
        columns: 2;
        p {
          text-align: left;
        }
      }

      p {
        line-height: 20px;
      }

      li {
        font-family: "Montserrat-Regular";
        font-size: $regular;
        color: $text-color;
      }
    }

    .centered {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        z-index: 1;
        margin-top: $margin-sm;
      }
    }

    .container-abstract,
    .detail-fact {
      position: relative;
      overflow: hidden;
      margin-bottom: $margin-md;
      box-sizing: border-box;
      &.hide {
        height: 120px;
        padding-bottom: 30px;
        p {
          max-height: 100px;
          overflow: hidden;
        }
      }
    }

    .gradient {
      position: absolute;
      z-index: 0;
      top: 0;
      height: 120px;
      width: 100%;
      background: linear-gradient(
        0deg,
        $light-grey 0%,
        $light-grey 30%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    h2,
    .no_bullets p {
      position: relative;
      font-family: "Montserrat-SemiBold";
      color: $primary-color;
      font-size: $title;
    }

    h3 {
      position: relative;
      font-family: "Montserrat-SemiBold";
      font-size: $medium;
      color: $blue;
    }

    p {
      margin-top: 0;
      text-align: justify;
    }

    ul:not(.tags-qes):not(.tags-filters) {
      padding-left: $margin-sm;
      list-style: none; /* Remove default bullets */
    }

    ul:not(.tags-qes):not(.tags-filters) li {
      position: relative;
      margin: 10px 0;
    }

    ul:not(.tags-qes):not(.tags-filters) li::before {
      content: "";
      position: absolute;
      left: 0;
      top: 6px;
      background-color: $primary-color;
      display: inline-block;
      margin-left: -1em;
      width: 5px;
      height: 5px;
      border-radius: 4px;
    }

    .title-section {
      text-transform: uppercase;
      font-family: "Montserrat-Medium";
    }

    .sub-title {
      display: inline-block;
      margin-bottom: 3px;
      color: $blue;
      padding: 3px 5px;
      border-radius: 4px;
      text-decoration: underline;
    }

    .file-link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 25px;
      margin: 10px 0;

      > a {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      i,
      img {
        // height: 14px;
        display: inline-block;
        width: 25px;
        margin-bottom: 0;
        margin-right: 5px;
        margin-left: 0;
      }
    }

    .linked-ressource,
    .docs-similaires {
      display: flex;
      flex-wrap: wrap;
      margin: 0 calc(#{$margin-sm}* -1);

      h5 {
        border-top: solid 1px $md-grey;
        width: 100%;
        margin: 0 $margin-sm;
        padding-top: $margin-md;
        font-size: $regular;
      }

      .center {
        text-align: center;
        min-width: 100%;
      }

      > div {
        flex: 1 1 27%;
        max-width: 33%;
        min-width: 33%;
        padding: $margin-sm;
        box-sizing: border-box;

        div {
          text-decoration: none;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }

          > p {
            max-height: 32px;
            min-height: 32px;
            margin-bottom: 0;
            margin-top: 5px;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            word-spacing: normal;
          }
        }
      }
    }

    .docs-similaires .image-doc,
    .linked-ressource .image-doc {
      position: relative;
      width: 100%;
      padding-top: 70%;
      background-position: center;
      background-size: cover;
      // $disabled-blue -> opacity 0.15
      background-color: rgba(110, 112, 124, 0.15);

      p {
        width: 100%;
        text-align: center;
        white-space: nowrap;
        color: rgba(110, 112, 124, 0.25);
      }

      &.empty {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        transform: translate(-50%, -50%);
      }
      > p {
        position: absolute;
        top: 40%;
        left: 50%;
        font-family: "Montserrat-SemiBold";
        font-size: $small;
        color: $disabled-blue;
        transform: translateX(-50%);
      }
    }
  }

  .separator {
    width: 30%;
    height: 1px;
    background-color: $md-grey;
    margin-top: $margin-sm;
    &.tags {
      margin-top: 0;
      width: 100%;
    }
  }

  .morepics {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    > div {
      position: relative;
      display: flex;
      width: 12.5%;
      padding-top: 12.5%;

      height: auto;
      margin: $margin-sm 0.85%;
      align-items: center;
      justify-content: center;
      background-color: white;
      background-position: center;
      background-size: cover;
      cursor: pointer;

      .overlay {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        bottom: 0;
        width: 100%;
        background-color: rgba(255, 125, 103, 0.4);
      }
    }
  }

  .date {
    margin: 3px 0 $margin-sm 0 !important;
    margin: 0;
    font-family: "Montserrat";
    color: $disabled-blue;
    font-size: 12px;
    margin-right: 5px;
  }

  a,
  p {
    font-family: "Montserrat-Regular";
    font-size: $regular;
    color: $text-color;
  }

  .source {
    position: relative;
    padding: $margin-sm $margin-xs;
    // background-color: $primary-color;
    border-radius: 8px;
    border: solid 1px $md-grey;
    margin: $margin-sm 0;
    color: $blue;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;

    > span {
      font-family: "Montserrat-Regular";
      font-size: $regular;
    }

    .btn-copy {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;

      top: 0;
      right: 0;
      height: 100%;
      width: 50px;
      background-color: $light-grey;
      box-shadow: 0 0 0 1px $md-grey;
      border-radius: 0 8px 8px 0;
      padding: 0;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: $md-grey;
      }

      img {
        height: 25px;
        margin: 0;
      }
    }

    .copied {
      position: absolute;
      z-index: 200;
      top: 50%;
      right: 60px;
      font-family: "Montserrat-SemiBold";
      font-size: $small;
      background-color: $primary-color;
      color: $white;
      border-radius: 8px;
      padding: $margin-xs $margin-sm;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      transform: translateY(100%);

      &.in {
        opacity: 1;
        transform: translateY(-50%);
      }
    }
  }

  .container-comments {
    position: relative;
    border-top: solid 1px $md-grey;

    .container-comment {
      position: relative;
      .gradient {
        position: absolute;
        z-index: 2;
        width: 100%;
        bottom: 0;
        top: 0;
        background: linear-gradient(
          0deg,
          $light-grey 0%,
          $light-grey 10%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    .btn-comments {
      z-index: 1;
    }

    .header-comments {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3 {
        font-family: "Montserrat-SemiBold";
        margin-bottom: $margin-sm;
        margin-top: $margin-md;
      }

      p {
        color: $primary-color;
        text-decoration: underline;
        margin: 0;
      }
    }

    .author {
      margin-bottom: 5px;
      color: $text-color;
      margin-bottom: $margin-xs;
      b {
        font-family: "Montserrat-SemiBold";
      }
    }

    .comments {
      box-sizing: border-box;
      padding: $margin-sm;
      margin-bottom: $margin-sm;
      border: solid 1px $md-grey;
      border-radius: 7px;
      font-size: $regular;
      font-family: "Montserrat-Regular", sans-serif;
      p {
        margin: 0;
      }
    }
  }
}
