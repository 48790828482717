@import '../../../scss/variables.scss';

.burger{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 27px;
  height: 20px;

  div{
    position: absolute;
    width: 27px;
    height: 3px;
    background: $blue;
    transition: all .4s ease-in-out;
    &:not(.cross):first-child{
      top:0;
    }
    &:not(.cross):nth-child(2){
      top:50%;
      transform: translateY(-50%);
    }
    &:not(.cross):nth-child(3){
      bottom:0;
    }
  }

  .cross{
    background: transparent;
  }

  .crossopen{
    background: $blue;

    &:not(:last-child){
      transform: rotate(45deg);
    }
    &:last-child{
      transform: rotate(-45deg);
    }
  }

  &.open > div:not(.cross){
    background-color: transparent;

  }

}
