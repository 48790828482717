.exploration.light .newspaper{


  .container-filters p,
  .container-filters i,
  .container-filters ul li{
    color : $blue
  }

  .zero-doc{
    color : $blue;
  }

  .filters-list.inline ul li{
    color : $blue
  }

  .dropdown{
    background-color: $light-grey;
  }


  .container-newspaper{
    .sub{
      color: $blue;
    }
  }

  .title-gradient{
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, $light-grey 100%);
  }

  .document:after{
    background: linear-gradient(180deg, rgba(245,245,248,0) 0%,rgba(245,245,248,1) 80%, rgba(245,245,248,1) 100%);
  }

  .document:before{
    background-color: $primary-color;
  }

  .container-more-info .abstract{
    color : $blue;
  }

  .pictos .__react_component_tooltip.type-dark.place-top:after {
    border-top-color: $blue;
  }

}

.exploration:not(.light) .newspaper{

  .dropdown{
    background-color: lighten($blue,10%);
  }

  .document:after{
    background: linear-gradient(180deg, rgba(21,24,69,0) 0%,rgba(21,24,69,1) 80%,rgba(21,24,69,1) 100%);
  }

  .document:before{
    background-color: $disabled-blue;
  }

  .container-more-info .abstract{
    color : $pure-white;
  }

  .title-gradient{
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, $blue 100%);
  }

}

.exploration.expand .newspaper.filters-opened{
   .container-newspaper .document,
   .container-newspaper .container-loader{
     flex: 1 1 100%;
     max-width: 100%;
     min-width: 100%;

     @include breakpoint(xs) {
       flex: 1 1 50%;
       max-width: 50%;
       min-width: 50%;
     }

     @include breakpoint(sm) {
       flex: 1 1 33.3%;
       max-width: 33.3%;
       min-width: 33.3%;
     }

     @include breakpoint(md) {
       flex: 1 1 25%;
       max-width: 25%;
       min-width: 25%;
     }

     @media screen and (min-width: 1165px){
       flex: 1 1 33.3%;
       max-width: 33.3%;
       min-width: 33.3%;
     }

     @include breakpoint(xl) {

       flex: 1 1 25%;
       max-width: 25%;
       min-width: 25%;
     }

   }

}

.exploration.expand .newspaper{
   .container-newspaper .document,
   .container-newspaper .container-loader{
     flex: 1 1 100%;
     max-width: 100%;
     min-width: 100%;

     @include breakpoint(xs) {
       flex: 1 1 50%;
       max-width: 50%;
       min-width: 50%;
     }

     @include breakpoint(sm) {
       flex: 1 1 33.3%;
       max-width: 33.3%;
       min-width: 33.3%;
     }

     @include breakpoint(md) {
       flex: 1 1 25%;
       max-width: 25%;
       min-width: 25%;
     }

     @include breakpoint(xl) {
       flex: 1 1 20%;
       max-width: 20%;
       min-width: 20%;
     }
   }

}

.exploration .newspaper .container-newspaper .document,
.exploration .newspaper .container-newspaper .container-loader{
  flex: 1 1 50%;
  max-width: 50%;
  min-width: 50%;
}

.newspaper{
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  .react_tooltip.place-top{
    // position: fixed;
    z-index: 1000;
  }

  .zero-doc{
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%,-50%);
    font-family: "Montserrat-SemiBold";
    opacity: 0.3;
    font-size: $titleH1;
  }

  &.filters-opened{
    .header-newspaper{

      @media screen and (min-width: #{$md-screen}){
        $newspaper-filter-width : calc(#{$advanced-filter-width} + 20px);
        width: calc(100% - #{$newspaper-filter-width}) !important;
      }
    }

    .container-loader{
      @media screen and (min-width: #{$md-screen}){
        left: calc(50% + #{$advanced-filter-width}/2 - 40px);
      }
    }

  }

  .header-newspaper{
    $newspaper-filter-width : 20px;
    position: fixed;
    display: flex;
    align-items: flex-end;
    z-index: 2;
    width: 100%;
    margin: 0 auto;
    padding: 0px;
    box-sizing: border-box;
    border-radius: 20px 0 0 0;
    flex-wrap: wrap;

    transition: width .5s ease-in-out;

    // @media screen and (min-width: #{$sm-screen}){
    //   padding-right: 80px;
    // }
    //
    // @media screen and (min-width: #{$md-screen}){
    //   padding-right: 0px;
    //   width: calc(100% - #{$newspaper-filter-width} - 80px);
    // }


    &.light{
      background: $white !important;
    }
    &:not(.light){
      background: $blue !important;
    }



    h2{
      position: relative;
      margin: 0;
    }

    .container-search{
      @include topbarView()
    }

  }

  .container-filters .filters-list p {
    svg{
      margin: 0 $margin-md 0 5px;
      width: 12px;
      height: 12px;
    }
    i{
      color : white;
    }
  }

  .container-filters{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin: $margin-sm auto 0 auto;
    padding: 0 $margin-sm 0 $margin-sm;
    box-sizing: border-box;

    @media screen and (min-width: 620px){
      padding: 0 $margin-md 0 $margin-md;
    }

    @media screen and (min-width: $sm-screen){
      padding: 0 110px 0 $margin-md;
      margin: $margin-xs auto 0 auto;
    }

    @include dropdownFilter();

    .filters-list .select.sortBy{
      z-index: 12;
    }

    .container-filters-list{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .filters-list{

      &.dropdown{
        padding: 0 $margin-sm;
        border-radius : 4px;
        height: 42px;
        margin-left: 8px;
      }

      .select{
        z-index: 1;
        min-width: 100%;
        &.sortBy{
          top: 40px;
        }
      }
      p i { color : white}

      ul{
        width: auto !important;
        padding: 0 5px !important;
        li {
          svg{
            position: relative;
            top:1px;
            width: 12px;
            height: 12px;
          }
          color:$disabled-blue;
        }
      }

    }

    .filters-list:not(.inline){

      ul{
        list-style: none;
        // width: calc(100% - 350px);
        padding: 0;
        li{
          position: relative;
          // display: inline-block;
          margin-left: 5px;
          cursor: pointer;
          font-size: $regular;
          white-space: nowrap;
          padding: 5px 0;


        }
      }

    }

    .filter-date{
      >p {
        line-height: 42px;
      }
    }

  }

  .container-newspaper{
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
    // justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;

    &.loading{
      opacity: 0.6;
      pointer-events: none;
    }

    .loaded{
      width: 100%;
      text-align: center;
      font-size: $small;
      padding: $margin-md 0;
      color : $disabled-blue;
    }

    .scrollTop{
      right: 0;
      bottom: 64px;
      @media screen and (min-width: #{$sm-screen}){
        bottom: 14px;
      }

    }

    .container-loader{
      position: fixed;
      top: calc(50% + 78px);
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 200px;

      // background-color: red;

      @media screen and (max-width: #{$md-screen}){
        left:0 !important;
        right: 0 !important;
        transform: translateX(0);
        min-height: 0px;

      }

      &.fullwidth{
        width: 100%;
        min-width: 100% !important;
      }

      >img{
        position: relative;
        top: -35px;
        width: 35px;
      }
    }

    .document{
      position: relative;
      z-index: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: $margin-sm;
      box-sizing: border-box;
      text-decoration: none;
      color : white;
      cursor: pointer;
      overflow: hidden;

      button.read-more{
        position: absolute;
        z-index: 1;
        bottom: -50px;
        left:50%;
        transform: translateX(-50%);
        padding: 5px 10px;
        border-radius: 13px;
        transition: bottom .6s ease-in-out;
        background-color: transparent;
        text-decoration: underline;
        color: $primary-color;
        // @include box-shadow;
      }

      @media screen and (min-width: 620px){
        &:after{
          content : "";
          position: absolute;
          display: block;
          bottom: -20px;
          height: 120px;
          width: 100%;
          pointer-events: none;
          opacity: 1;
          transition: bottom .6s ease-in-out;
        }
      }



      @media screen and (min-width: 620px){
        &:hover{
          &:after{
            bottom: 2px;
          }
          button.read-more{
            bottom: 8px;
          }
          .image-doc{
            padding-top: 0% !important; 
          }
          .container-icons .icon{
            transform: scale(0);
          }
          .container-more-info{
            padding-top: 70% !important; 
          }
        }
      }



      @media screen and (min-width: 620px){
        flex-direction: column;
        padding: $margin-sm $margin-md;
        margin-bottom: $margin-sm;
      }

      &:before{
        content : "";
        position: absolute;
        z-index: 2;
        display: block;
        bottom: 0;
        width: calc(100% - #{$margin-sm}*2);
        height: 2px;
        @media screen and (min-width: 620px){
          width: calc(100% - #{$margin-md}*2);

        }
      }

      .__react_component_tooltip.place-top {
          margin-top: -8px;
      }
      .__react_component_tooltip.type-dark.place-top:after {
        border-top-color: white;
      }

      .container-icons{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        height: 28px;
        margin-bottom: -28px;
        padding: 4px;
        p{ color : $primary-color}

        .icon{
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          height: 20px;
          width: 20px;
          border-radius: 10px;
          font-size: $small;
          box-shadow : 0 3px 15px 0 rgba(255,125,103,.5);
          margin-right: 4px;
          transition: transform .6s ease-in-out;
          transform-origin: center;
        }

      }

      .container-more-info{
        position: relative;
        display: none;
        transition: all .6s ease-in-out;
        // overflow: hidden;
        width: 100%;

        @media screen and (min-width: 620px){
          display: block;
          width: 100%;
          height: inherit;
          min-width : inherit;
        }

        .abstract{
          top:0;
          position: absolute;
          left:0;
          right:0;
          padding-left: 10px;
          display: block;
          font-family: "Montserrat-Regular";
          font-size: $regular;
          line-height: 18px;
        }

      }

      .image-doc{
        position: relative;
        width: 85px;
        min-width: 85px;
        height: 85px;
        padding-top: 0;
        background-position: center;
        background-size: cover;
        // $disabled-blue -> opacity 0.15
        background-color: rgba(110,112,124,0.15);
        transition: all .6s ease-in-out;


        @media screen and (min-width: 620px){
          width: 100%;
          height: inherit;
          min-width : inherit;
          padding-top: 70%;
        }

        >img{
          position: absolute;
          top:50%;
          left:50%;
          width: 30px;
          transform: translate(-50%,-50%);
        }
        >p{
          position: absolute;
          top:40%;
          left:50%;
          font-family: "Montserrat-SemiBold";
          font-size: $small;
          color : $disabled-blue;
          transform: translateX(-50%);
          white-space: nowrap;
        }
      }

      .pictos{
        &.only-on-mobile{
          display: block;
          @media screen and (min-width: 620px){
            display: none;
          }
        }

        &.hide-on-mobile{
          padding-top: $margin-xs;
          display: none;
          @media screen and (min-width: 620px){
            display: block;
          }
        }

        span{
          margin-right: $margin-xs;
        }
      }

      .container-info{
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
        width: calc(100% - 85px);
        padding-left: $margin-xs;
        box-sizing: border-box;
        @media screen and (min-width: 620px){
          width: 100%;
          display: block;
          flex-direction: inherit;
          justify-content: inherit;
        }

        .rubrique{
          font-family: "Montserrat-SemiBold";
        }

      }

      .sub p {
        font-size: $small;
        font-family: "Montserrat-Regular";

        @media screen and (max-width: 620px){
          &:last-child{
            margin-bottom: 0;
          }
        }

      }

      .title{
        font-size: $regular;
        font-family: "Montserrat-SemiBold";
        margin-bottom: 0;

        &.title-only-on-mobile{
          position: relative;
          display: block;

          .title-gradient{
            position: absolute;
            width: 100%;
            height: 10px;
            bottom: 0;
          }

          @media screen and (min-width: 620px){
            display: none;
          }
        }

        &.title-hide-on-mobile{
          display: none;
          @media screen and (min-width: 620px){
            display: block;
          }
        }

        @media screen and (max-width: 620px){
          margin-top : 0;
        }
      }


    }
  }
}
