@import '../../../../scss/variables.scss';

.loading-document{
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100%;
  .text{
    width: 100%;
    padding-left: $margin-sm;
    box-sizing: border-box;
  }
}
