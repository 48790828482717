@import '../../../../scss/variables.scss';
@import '../../../../scss/mixins.scss';
@import '../home-mixins.scss';

.block-filter{
  position: relative;
  padding: $margin-md;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  align-self: stretch;
  overflow: hidden;
  transition: all 1s ease-in-out;
  background-color: $light-blue;

  // @media screen and  (min-width: 1350px) and (max-width: 1450px){
  //   max-width: 370px;
  // }

  // @media screen and  (min-width: 1450px){
  //   max-width: 400px;
  // }

  &.light{
    @include box-shadow2(light);
  }

  &:not(.light){
    @include box-shadow2();
  }

  .block-title{
    display: flex;
    justify-content: space-between;
    margin: 0 0 $margin-md 0;
    h3,p{
      font-family: "Montserrat-SemiBold";
      font-size: $medium;
      color : $white;
      margin: 0;
    }

    span{
      display: flex;
    }

    .count{
      display: inline-block;
      white-space: nowrap;
      color : $primary-color;
      vertical-align: top;
      padding-right: $margin-xs;
    }

    img{
      height: 20px;
      cursor: pointer;
    }
  }

  .no-documents{
    margin: 0;
    color : $disabled-blue;
    font-family: "Montserrat-SemiBold";
  }

  .list-documents{
    padding: 0;
    margin: 0;
    li{
      display: inline-flex;
      list-style: none;
      width: 100%;
      cursor: pointer;
      color : $white;
      *{
        cursor: pointer;
      }

      &:not(:last-child){
        margin-bottom: 20px;
      }

      label{
        display: block;
        font-family: "Montserrat-Medium";
        font-size: $regular;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-height: 50px;
      }

      .date{
        font-family: "Montserrat-Light";
        font-size: $regular;
        margin: 0;
      }

    }
  }

  .image-doc{
    position: relative;
    width: 64px;
    min-width: 64px;
    height: 64px;
    margin-right: $margin-xs;
    padding-top: 0;
    background-position: center;
    background-size: cover;
    background-color: rgba(110,112,124,0.15);
    overflow: hidden;
    border-radius: 8px;


    // @media screen and (min-width: 620px){
    //   width: 100%;
    //   height: inherit;
    //   min-width : inherit;
    //   padding-top: 70%;
    // }

    >img{
      position: absolute;
      top:50%;
      left:50%;
      width: 30px;
      transform: translate(-50%,-50%);
    }
    >p{
      position: absolute;
      top:40%;
      left:50%;
      font-family: "Montserrat-SemiBold";
      font-size: $small;
      color : $disabled-blue;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }

  @include link-entry;
  .link-entry{
    text-align: center;
    margin-top: $margin-sm;
    p{
      background-color: rgba(222, 222, 235, 0.06);
      margin:0;
    }
  }

}
