@import '../../../../scss/variables.scss';
@import '../../../../scss/mixins.scss';

//SLIDER-IMAGE
.slider-image{
  position: relative;

  img{
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }

  @media screen and (max-width: #{$sm-screen}){

    img{
      margin: 0 calc( -1*#{$margin-sm});
      min-width: calc( 100% + 2*#{$margin-sm});
      margin-bottom: $margin-sm;
    }
  }

  .arrow{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top:50%;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background: rgba(29,34,97,0);
    transition: all .3s ease-in-out;
    &:hover{
      background: rgba(29,34,97,.1);
    }
    img{
      position: relative;
      width: 30px;
      min-width: inherit;
      margin: 0;

    }
    &:first-child{
      left:-5px;
      img{left: 5px;}
      @media screen and (min-width: #{$sm-screen}){
        left:20px;
      }
    }
    &:nth-child(2){
      right:-5px;
      img{left: 3px}
      @media screen and (min-width: #{$sm-screen}){
        right:20px;
      }
    }
  }
}

.morepics{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  >div{
    position: relative;
    display: flex;
    width: 12.5%;
    padding-top: 12.5%;

    height: auto;
    margin: $margin-sm 0.85%;
    align-items: center;
    justify-content: center;
    background-color: white;
    background-position: center;
    background-size: cover;
    cursor: pointer;


    .overlay{
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top:0;
      bottom: 0;
      width: 100%;
      background-color: rgba(255,125,103,.4);
    }
  }
}
