.dropdown-entries{
  position: absolute;
  z-index: 1000;
  width: auto;
  height: auto;
  border-top: solid 2px #ECECF1;
  box-sizing: border-box;
  ul{
    display: flex;
    background-color: $light-grey;
    height: auto !important;
    padding: $margin-sm 0;
    margin: 0;
    border-radius: 0 0 16px 16px;
    li{
      display: inline-block;
      max-width: 300px;
      padding : 0 $margin-sm;
      cursor: pointer;
      &:not(:last-child){
        border-right: solid 1px $md-grey;
      }

      a.bold label{
        font-family: "Montserrat-Bold";
      }

      p,label{
        margin: 0;
      }

      label{
        font-family: "Montserrat-SemiBold";
        color : $text-color;
        font-size: $regular;
        cursor: pointer;
        &:hover{
          color : $primary-color;
        }
      }

      p{
        font-size: $small;
        margin-top: 5px;
      }

    }
  }
}
