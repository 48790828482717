.redirect{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img{
    width: 30px;
  }

}
