.select-platforms{

  border-bottom: solid 3px lighten($md-grey,9%);
  margin-bottom: $margin-sm;

  >div:first-child>p{
    font-family: "Montserrat-SemiBold";
    font-size: $medium;
    margin-top: 0;
  }

  >div:first-child>ul{
    padding: 0;
    margin: 0 0 $margin-sm 0;

    .toggle-platforms{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      text-transform: uppercase;
      &.opened{
        background-color: lighten($text-color,10%) !important;
        border-radius: 8px 8px 0px 0px;
      }
    }

    li:not(.toggle-platforms){
      &:last-child{
        border-radius: 0px 0px 8px 8px;
      }
    }

    li{
      list-style-type: none;
      font-family: "Montserrat-SemiBold";
      font-size: $regular;
      padding: $margin-sm;
      box-sizing: border-box;
      width: 100%;
      cursor: pointer;
      transition-property: color, background-color;
      transition-duration:  .3s;
      transition-timing-function: ease-in-out;

      &.selected{
        background-color: $primary-color;
        color: $white;
      }
      &:not(.selected){
        background-color: $text-color;
        color: $white;
        &:hover{
          background-color: lighten($text-color,10%) !important;
        }
      }
    }
  }

}
