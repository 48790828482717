.filters{
  position: absolute;
  z-index: 103;
  top: $header-height;
  bottom: 0;
  min-width: 100%;
  background-color: $light-grey;
  box-sizing: border-box;
  overflow: hidden;
  @include box-shadow();

  @media screen and (min-width: $sm-screen){
    top: calc(#{$header-height} + 20px);
    left: 20px;
    bottom: 20px;
    border-radius: 15px;
    min-width: auto;
  }

  >div:first-child{
    position: relative;
    z-index: 1;
    height: 100%;
    overflow-y: auto;
    padding: $margin-md $margin-md $margin-md $margin-sm;
    box-sizing: border-box;
    overflow-x: hidden;
    @media screen and (min-width: #{$sm-screen}){
      padding: $margin-md $margin-lg $margin-md $margin-md;
    }
    >*{
      // position: relative;
      // z-index: 1;
    }
  }

  @include close();
  .close{
    position: absolute;
    top:10px;
    right:10px;
  }

  .container-pinup{
    margin: $margin-sm 0 $margin-md 0;
    ul{
      padding: 0;
      margin: 0;
      li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style-type: none;
        padding: $margin-sm $margin-sm;
        background-color: $md-grey;
        border-radius: 8px;
        transition: all .4s ease-in-out;
        cursor: pointer;

        &.open,
        &:hover{
          background-color: $primary-color;
          span:last-child{
            color : $white;
          }
        }
        span:last-child{
          font-family: 'Montserrat-SemiBold';
          color : #44466A;
        }
        &:not(:last-child){
          margin-bottom: $margin-xs
        }
      }
    }
  }

  .container-checkbox{
    display: flex;
    flex-wrap: wrap;
    margin-top: $margin-sm;
    @include checkbox();

    .field-checkbox{
      margin-right: $margin-sm;
      label{ white-space: nowrap; cursor: pointer }
    }

  }

  .title{
    position: relative;
    font-family: 'Montserrat-SemiBold';
    color : $text-color;
    text-transform: uppercase;
    >span:not(.icon):hover{
      text-decoration: underline;
    }
    &.interactive{
      cursor: pointer;
    }
    .icon{
      margin-right: 7px;
    }

    &-advanced svg{
      margin-right: $margin-xs
    }

  }

  .container-rubrique{
    margin: 0 0 $margin-md 0;
  }

  .container-restrict-search{
    margin: 0 0 $margin-sm 0;
  }

  .container-fulltext-search{
    margin: $margin-md 0 $margin-sm  0;
    .inputField{
      position: relative;
      width: 100%;
      >span:not(.clearField){display: none;}

      .clearField{
        position: absolute;
        right:4px;
        border-bottom: 0;
        i{
          line-height: 38px;
        }
      }

      input{
        border: none;
        width: 100%;
        padding: 2px 8px;
        border-radius: 8px 0px 0px 8px;
        height: 38px;
      }
    }

    button{
      background-color: $primary-color;
      height: 38px;
      box-shadow : 0 3px 15px 0 rgba(255,125,103,.5);
      color: #964335;
      transition: all .3s ease-in-out;
      &:hover{
        background-color: darken(#FF7D67,7%);
      }
    }

    .title:nth-child(1){
      position: relative;
      margin-top: 0;
    }
    .title:after{
      background-color : #9bb754;
    }

    .btn-search{
      padding: 0px 15px;
      img{
        position: relative;
        width: 25px;
      }
    }

  }

  .container-advanced-search{

    .title-advanced{
      margin-bottom: $margin-xs !important;
      .container-count{
        position: relative;
        top:-2px;
        span:first-child{
          margin-left: $margin-xs;
        }
      }
    }

    .container-title{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title,ul{
        margin: $margin-xs 0;
      }

    }

    .list-tags{
      padding: 0;
      margin: 0 0 0 0;
      li{
        display: inline-block;
        list-style-type: none;
        margin: 4px 0;
        &:not(:last-child){
          margin-right: $margin-xs
        }
      }
    }

    .container-advanced-filters{
      // padding: $margin-xs 0;

      .title{
        margin: $margin-xs 0;
        &.actived{
          border-bottom: solid 1px $primary-color;
          padding-bottom:  $margin-xs;
        }
        &:after{
          background-color : #4E529D;
        }
      }

    }

    .container-filters{
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      >div:not(.large){
        position: relative;
        display: inline-block;
        width: 98%;
      }



      .container-btn.nopadding{
        padding: $margin-sm 0;
      }

      .container-btn{
        width: 100%;

        button{
          color : white;
        }

        button.refine-lvl2{
          background-color: #8180D9;
          box-shadow : 0 3px 15px 0 rgba(129, 128, 217,.5);
        }



        button:first-child{
          margin-right: $margin-sm;
        }

        button.disabled{
          background-color: $md-grey;
          color: $white;
          box-shadow: 0 4px 12px 0 rgba(200,200,237,.3);
          pointer-events: none;
        }
      }


      .title-filter{
        font-family: 'Montserrat-SemiBold';
        text-transform: capitalize;
        color : #44466A;
        width: 100%;
        padding: $margin-sm $margin-sm;
        background-color: $md-grey;
        border-radius: 8px;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        cursor: pointer;
        transition: all .4s ease-in-out;

        &:not(:last-child){
          margin-bottom: $margin-xs
        }

        &:last-child{
          margin-bottom: $margin-sm
        }

        // li{
        //   display: flex;
        //   justify-content: space-between;
        //   align-items: center;
        //   list-style-type: none;
        //   padding: $margin-sm $margin-sm;
        //   background-color: $md-grey;
        //   border-radius: 8px;
        //   transition: all .4s ease-in-out;
        //   cursor: pointer;
  
        //   &.open,
        //   &:hover{
        //     background-color: $primary-color;
        //     span:last-child{
        //       color : $white;
        //     }
        //   }
        //   span:last-child{
        //     font-family: 'Montserrat-SemiBold';
        //     color : #44466A;
        //   }
        //   &:not(:last-child){
        //     margin-bottom: $margin-xs
        //   }
        // }

        &.open,
        &:hover{
          background-color: $primary-color;
          span:last-child{
            color : $white;
          }
        }

        svg{
          margin-right: 7px;
        }

      }

      .title-sub-filters{
        font-family: 'Montserrat-SemiBold';
        color : $text-color;
        width: 100%;
        padding: $margin-xs 11px $margin-xs 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        label:hover{
          text-decoration: underline;
        }

        >div{
          max-width: calc(100% - 17px);
        }

        >span{
          display: inline-flex;
          margin-right: 7px;
          height: 10px;
          justify-content: center;
          align-items: center;

        }
        >span,label,img{
          cursor: pointer;
        }
      }

      ul.container-list-checkboxes{
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
        padding: 0 0 0 $margin-sm;
        margin: 0;
        &:after{
          content : "";
          position: absolute;
          left: 0;
          top: 50%;
          display: block;
          height: calc(100% - #{$margin-md});
          width: 3px;
          border-radius: 2px;
          background-color: $md-grey;
          transform: translateY(-50%);
        }

        &:not(.cumul) .img-checkbox{
          display: none;
        }

        >span{
          position: relative;
          display: inline-flex;
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          &:after{
            content : "";
            position: absolute;
            left: 0;
            top: 50%;
            display: block;
            height: calc(100% - #{$margin-md});
            width: 3px;
            border-radius: 2px;
            background-color: $md-grey;
            transform: translateY(-50%);
          }
          li:not(.title-sub-filters){
            padding: 8px 0 8px $margin-sm ;
          }
        }

        li:not(.title-sub-filters){
          label.selected{
            color:#8180D9;
          }
        }

        li.title-sub-filters{
          label.selected{
            color:$primary-color;
          }
        }

        li{
          width: 100%;
          list-style: none;
          box-sizing: border-box;
          padding: $margin-xs 0;
          line-height: 22px;

          label,img{
            cursor: pointer;
          }

          label:hover{
            text-decoration: underline;
          }


          img{
            position: relative;
            top:3px;
            margin-right: $margin-xs;
          }
        }
      }

      >div{
        margin-bottom: $margin-sm;
      }

      >div.multi{
        position: relative;
        border-radius: 2px;
        box-shadow: 0 0 0 5px lighten($primary-color, 20%);



        &:after{
          content:"";
          position: absolute;
          z-index: -1;
          display: block;
          top:0;
          bottom: 0;
          width: 100%;
          background-color: lighten($primary-color, 20%);
        }
      }

    }

    .filters-exclude{
      position: relative;
      z-index: 100;
      display: block;
      align-items: flex-start;
      justify-content: center;
      // width: calc(100% + 55px);

      .title:after{
        background-color : #ff0000;
      }

      img{
        position: relative;
        top:7px;
        margin-right: 10px;
      }

      .global-search{
        position: relative;
        justify-content: flex-end;
        .search-field{
          right: auto;
          width: calc(100% - 34px) !important;
        }
        .icon{
          transform: translateX(0) !important;
          margin-left: 0 !important;
          left:0;
        }
        .toggle-expand.expanded{
          right: 0;
          left:34px;
          width: calc(100% - 38px) !important;
        }
        // align-items: flex-end ;
        // width: calc(100%-90px);
      }

      @include globalSearch();

    }

  }

  .search-field{
    &.search-rubrique{
      border-radius: 8px;
    }
    .filters-rubrique__indicators>div{
      padding: 0px !important;
      &:last-child{
        padding-right: 5px !important;
      }
    }
  }


  // h1{
  //   font-family: 'Montserrat-SemiBold';
  //   color:$primary-color;
  // }

  @include GraphRange();

}
