@import '../../../../scss/variables.scss';
@import '../../../../scss/_mixins.scss';

.sso{
  display: flex;
  align-items: center;
  justify-content: center;

  >div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
  }

  .container{
    display: flex;
    flex-direction: column;

    h2{
      font-family: "Montserrat-SemiBold";
      font-size: $titleH1;
      line-height: 40px;
      color: #004a98;
      margin-bottom: $margin-sm;
    }

    a{
      display: block;
      margin: $margin-xs 0;
      width: 200px;
      button{
        width: 100%;
        height: 36px;
        border-radius: 18px;
        background-color: #004a98;
      }
    }
  }

  .image-container{
    height: 100vh;
    width: 50%;
    object-fit: cover;
    background-image: url("../../../../assets/images/michelin-fond.jpg");

    img{
      position: absolute;
      bottom: 0;
      margin-left: -50px;
    }
  }
}
