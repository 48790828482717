@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.document-offline{
  position: relative;
  height: 100%;
  background: $blue;
  padding: 100px;

  .container-document{
    position: fixed;
    z-index: 104;
    padding: 30px;

    box-sizing: border-box;
    background-color: $light-grey;
    overflow-x: hidden;
    overflow-y: auto;

    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;

    @media screen and (min-width: #{$sm-screen}){
      top: 50%;
      left:50%;
      transform: translate(-50%,-50%);
      border-radius: 15px;
      box-shadow: 0 10px 20px 0 rgba(0,0,0,.5);
      height: 90%;
      max-width: 960px;
      width: calc(100% - 60px);
    }

    @media screen and (min-width: #{$md-screen}){
      height: 80%;
    }

    .article-info{
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .date{
        margin: 3px 0 $margin-sm 0 !important;
        margin: 0;
        font-family: 'Montserrat';
        color: $disabled-blue;
        font-size: 12px;
        margin-right: 5px;
      }

      .collectby{
        display: flex;
        align-items: center;
        p{
          margin: 0;
          font-family: 'Montserrat';
          color: $disabled-blue;
          font-size: 12px;
          margin-right: 5px;
        }

        .state{
          color: $primary-color;
          font-family: 'Montserrat-SemiBold';
        }



      }


    }

    .take-away{
      border: solid 2px $primary-color;
      padding: $margin-sm $margin-sm $margin-sm $margin-sm;
      margin: $margin-xs 0 $margin-md 0;

      @media screen and (min-width: #{$md-screen}){
        padding: $margin-sm $margin-md $margin-md $margin-md;
      }

      legend{
        font-family: "Montserrat-SemiBold";
        font-size: $title;
        color : $primary-color;
        padding: 0 $margin-xs;
      }

      p:first-child{
        margin-top: 0;
      }

      p:last-child{
        margin-bottom: 0;
      }
    }


    p,
    a,
    li{
      font-family: "Montserrat-Regular";
      font-size: 14px;
      color: #151845;
    }

    ul li{
      position: relative;
      list-style: none;
      margin: 10px 0;
      &:before {
        content: "";
        position: absolute;
        left:0;
        top:6px;
        background-color: $primary-color;
        display: inline-block;
        margin-left: -1em;
        width: 5px;
        height: 5px;
        border-radius: 4px;
      }
    }

    >h1{
      position: relative;
      font-family: "Montserrat-Regular";
      font-size: $title !important;
      color: $text-color;
      margin-top: 0;

      @media screen and (min-width: #{$sm-screen}){
        font-size: $titleH1 !important;
      }

    }

    .abstract{
      display: block;
      margin-bottom: $margin-sm;

      p{
        font-family: "Montserrat-SemiBold";
        font-size: $medium;
        line-height: calc(#{$medium} + 5px );
        color : $disabled-blue;
        text-align: left;
        &:first-child{
          margin-top : 0;
        }
      }

    }

    .detail-fact{
      margin-top: $margin-md;
      columns: 2;

      p {
        text-align: left;
        line-height: 18px;


      }

      img{
        max-width: 100%;
      }

      .centered{
        display: flex;
        align-items: center;
        justify-content: center;
        button{
          z-index: 1;
          margin-top: $margin-sm
        }
      }
    }

    .contact p{
      font-family: "Montserrat-SemiBold";
    }

  }



}
