.exploration.light .geoViz{
  @include geomap("light");
  background-color: $white;
  .container-search .global-search .search-field>div{
    background-color: $white;
  }

}

.exploration:not(.light) .geoViz{
  @include geomap("");
  background-color: $blue;
  .container-search .global-search .search-field>div{
    background-color: $blue;
  }

}

.geolocalisation{

  &.filters-opened{
    @media screen and (min-width: #{$md-screen}){
      .container-loader{
        left : calc(50% + 220px);
      } 
    }
  }

  .container-export{
    top:90px !important;
    right : 20px;
  }

  @media screen and (max-width : $sm-screen){
    .container-export {
      display: none;
    }
  }

}

.geoViz{
  position: relative;
  path {
    cursor: pointer;
  }

  .info-export{
    width: 100% !important;
  }

  .tooltips{
    color: white !important;
  }

  .container-geo{
    display: flex;
    align-items: center;
    justify-content: center;
    &.loading{
      opacity: 0.6;
      pointer-events: none;
    }
  }

  .container-search{
    position: absolute !important;
    z-index: 2;
    top:0px;
    right:0;
    @include topbarView()
    width: 100%;
  }

}
