#container-exploration>.relationships{
  display: flex;
  align-items: center;
  justify-content: center;
}

.relationships{
  @include close();

  &.filters-opened{
    $filter-width : calc(#{$advanced-filter-width} + 20px);
    @media screen and (min-width: #{$md-screen}){
      .container-search{
        width: calc(100% - #{$filter-width}) !important;
      }
  
      .input-count,
      .relationships-filters{
        left : calc(#{$filter-width} + 30px);
      }
  
      .container-loader{
        left : calc(50% + 220px);
      } 
    }
  }

  .container-search{
    position: absolute !important;
    z-index: 0;
    top:0px;
    right:0px;
    @include topbarView();
    width: 100%;
  }

  .relationships-filters{
    position: absolute;
    z-index: 2;
    left: 15px;
    min-width: calc(100% - 70px - 35px);
    max-width: calc(100% - 70px - 38px - 22px);
    padding: 0 $margin-xs;
    border-radius: 8px;
    transition: left .4s ease-in-out;

    @media screen and (max-width: $sm-screen){
      &.with-searchbar{
        top: 96px;
      }

      &:not(.with-searchbar){
        top: 22px;
      }
    }

    @media screen and (min-width: 434px){
      left: 20px;
      padding: 0 $margin-sm;
      max-width: 300px;
      width: 300px;
      min-width: inherit;
    }

    @media screen and (min-width: $md-screen){
      left: $margin-md;
    }

    @media screen and (min-width: $sm-screen){
      top: 94px;
    }

  }

  &.hideLinks .networkViz .line{
    display: none;
  }

  .line{
    cursor: pointer;
  }

  .input-count{
    position: absolute;
    z-index: 1;
    top: 96px;
    right: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: $light-grey;
    transition: left .4s ease-in-out;

    @media screen and (max-width: #{$sm-screen}){
      &.with-searchbar{
        top: 96px;
      }

      &:not(.with-searchbar){
        top: 22px;
      }
    }

    @media screen and (min-width: 434px){
      right: 22px;
    }

    @media screen and (min-width: #{$sm-screen}){
      top: 22px;
      left: 22px;
      right: inherit;
      bottom: 20px;
      top: inherit;
    }

    &:not(.open){
      width: 38px;
    }

    &.open {
      padding: 8px $margin-sm;
      width: 100%;
      max-width: calc(100% - 72px);

      @media screen and (min-width: 434px){
        width: 370px;
      }


      .settings{
        position: absolute;
        top:0;
        right:0;
        // transform: translate(50%,-50%);
        width: 28px;
        height: 28px;
      }
    }

    .settings{
      background-color: $light-grey;
      width: 38px;
      height: 38px;
      border-radius: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img{
        width: 25px;
      }
      .close{
        position: relative;
        top:-1px;
        width: 16px;
        transform: scale(.9);
        &:after,&:before{ width: 16px}
      }
    }

    .btn-group{
      z-index: 2;
      button{
        display: block;
        width: 100%;
        font-family: "Montserrat-SemiBold";
        margin: $margin-xs 0;
        background-color: $primary-color;
        box-shadow: 0 4px 12px 0 rgba(208,2,37,.3);
      }
    }

    .countNodes,.countDocuments{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @media screen and (min-width: 434px){
        flex-direction: row;
        align-items: center;
      }

      ul{
        padding: 0;
        margin: 10px 0;
        li{
          position: relative;
          display: inline-block;
          font-size: $small;
          color: $text-color;
          margin: 0 4px;
          cursor: pointer;
          &.selected{
            color : $primary-color !important;
          }
        }
        li:not(:last-child):after{
          content:'';
          position: absolute;
          right:-5px;
          top:50%;
          transform: translate(-50%,-50%);
          display: block;
          width: 2px;
          height: 2px;
          background-color: $text-color;
          border-radius: 2px;
        }
      }

      // box-shadow: 0 4px 12px 0 rgba(208,2,37,.3);
      input{
        height: 28px;
        border-radius: 5px;
        padding-left: 8px;
        border: none;
        font-size: $small;
        color: $primary-color;
        width: 80px;
      }

      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .inputField span{ display: none}

      label{
        font-size: $small;
        font-family: "Montserrat-SemiBold";
        color: $primary-color;
      }

      @include help();

      .react-tooltip.place-top{
        margin-top: -15px;
        background-color: white;
        color:$primary-color !important;
      }
      //
      .__react_component_tooltip.type-dark.place-top:after {
        border-top-color: white;
      }

    }

    .export-vizualisation{
      display: inline-flex;
      align-items: center;
      p{
        display: inline-block;
        color:  $primary-color;
        margin-left: 10px;
        margin: $margin-xs 0;
        padding-left: $margin-xs;
        cursor: pointer;
      }
      img{
        position: relative;
        top: -1px;
        cursor: pointer;
      }
    }

  }

  /// DATA VIZ

  .networkViz{
    .circle,.label{
      position: absolute;
      z-index: 100;
      cursor : pointer;
      user-select: none;
    }
    .circle{
      stroke-opacity : 0.4
    }
    .line{
      position: absolute;
      z-index: 1;
    }

  }

}

.exploration:not(.light) .partnerships .networkViz {
    background-color: #151845;
}

.exploration.light{

  .relationships{

    .container-search .global-search .search-field>div{
      background-color: $white;
    }

    .input-count{


      li{
        color : $blue;
        &:after{
          background-color: $blue;
        }
      }
    }

    .relationships-filters{
      background-color: $light-white;
      li{
        color : $blue;
        &:after{
          background-color: $blue;
        }
      }
    }

    .networkViz{
      .label{
        fill: $blue;
      }

      .circle{
        stroke-width: 15px;
        .active{
          stroke: rgba(255,125,103,0.3);
        }
      }
      .line{
        stroke: rgba(69,70,106,0.3);
      }

    }

  }

}

.exploration.light{
  .relationships-filters{
    @include box-shadow()
  }
  .input-count{
    @include box-shadow()
  }
}

.exploration:not(.light){

  .relationships{

    .container-search .global-search .search-field>div{
      background-color: $blue;
    }

    .relationships-filters{
      background-color: $light-blue;
    }

    .networkViz{
      background-color: $blue;

      .label:not(.dark){
        fill: white;
      }

      .label.dark{
        fill: $blue;
      }

      .circle{
        stroke-width: 15px;
        &.active{
          stroke: rgba(255,125,103,0.3);
        }
      }
      .line{
        stroke: rgba(255,255,255,1);
      }


    }

  }


}
