@import '../../../scss/variables.scss';

.container-loader{
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 47%;
    transform: translate(-50%,-50%);

    &.light p{
        color : $blue;
    }

    // @media screen and (min-width: #{$md-screen}){
    //   left: calc(50% + #{$filter-width}/2);
    // }

    img{
      width: 30px;
    }
    p{
      font-family: "Montserrat-SemiBold";
      text-align: center;
    }
  }