@import '../../../scss/variables.scss';
@import '../../../scss/_mixins.scss';

.popup-about{
    position: absolute;
    z-index: 3;
    bottom: 30px;
    right:$margin-md;
    left:$margin-sm;
    padding: $margin-sm $margin-md $margin-sm $margin-sm;
    border-radius: 12px;
    box-sizing: border-box;
    background-color: $white;
    transition: all .6s ease;
    @include box-shadow();
    @include close();

    @media screen and (min-width: $sm-screen){
        right:45px;
        left:30px;
    }

    .icon-close{
      position: absolute;
      top:10px;
      right:10px;
      transition: all .6s ease;
    //   &:before,
    //   &:after{
    //     background-color: $blue;
    }

    &.close{
        box-shadow: 0px 3px 15px rgba(155, 165, 185, 0);
        .icon-close{
            transform: scale(0);
        }
        img,
        .container{
            transform: translateY(100%);
        }
    }

    label{
        display: inline-block;
        font-family: "MontSerrat-SemiBold";
        font-size: $medium;
        color: $text-color;
        margin-bottom: $margin-xs;
    }

    .mask{
        display: flex;
        overflow: hidden;
        img{
            max-width: 90px;
            margin-right: $margin-xs;
        }
    }

    img,
    .container{
        transition: all .6s ease;
    }

    .container-text{

        p{
            font-family: "MontSerrat-Regular";
            font-size: $regular;
            line-height: 18px;
            color: $text-color;
            margin-top: 0;
            margin-bottom: $margin-sm;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }

}