.footer{
  position: absolute;
  z-index: 9;
  height: 44px;
  bottom: 0;
  width: 100%;
  background: rgb(21,24,69);
  box-sizing: border-box;
  overflow: hidden;
  transition: all .4s ease-in-out;

  &.light{
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(21,24,69,0) 100%);
    .input-range__slider-container .input-range__label{
      background-color: $blue !important;
      color:white;
    }
  }

  &:not(.light){
    background: linear-gradient(0deg, rgba(21,24,69,1) 0%, rgba(21,24,69,0) 100%);
    .input-range__slider-container .input-range__label{
      background-color: white !important;
      color:$blue;
    }
  }

  >img:not(.loader){
    position: absolute;
    z-index: 1000;
    left:10px;
    top:9px;
    height: calc(#{$footer-height} - 20px);
  }

  .toggle-footer{
    position: absolute;
    z-index: 1000;
    right : 0px;
    bottom: 10px;
    background-color: $primary-color;
    border-radius: 11px;
    height: 18px;
    cursor: pointer;
  }

  &.minimize{
    padding-left: calc(#{$filter-width} + #{$margin-md});
    padding-right: calc(80px + #{$margin-md});
    margin: 0;

    >img:not(.loader){
      display: none;
    }

    .toggle-footer{
      bottom: 3px;
    }

    .container-input-range{
      padding: 0 83px 0 83px;
      margin-top: 10px;
    }

    .input-range__track .input-range__slider-container:nth-child(2) .input-range__label{
      transform: translateX(-115%);
    }

    .input-range__track .input-range__slider-container:nth-child(3) .input-range__label{
      transform: translateX(15%);
    }

    .input-range__slider-container .input-range__label{
      // bottom : 10px;
      display: inline-block;
      width: 76px;
      text-align: center;
      box-sizing: border-box;
      top: -14px !important;
      &:after{ display: none}
    }

    .input-range__slider{
      transform: scale(.7);
    }

    .overlay-left,
    .overlay-right{
      display: none;
      height: 24px !important;
    }

  }

  &:not(.minimize){
    height: $footer-height;
    padding: 20px 40px 20px 85px;
  }

  @include GraphRange();

  .loader{
    position: absolute;
    top:50%;
    left: calc(50% + #{$filter-width}/2);
    width: 90px;
    transform: translate(-50%,-50%);
  }

}
