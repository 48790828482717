// MOBILE VIEW IS BELOW ON DASHBOARD 720px

.exploration.light .dashboard{
  .header-dashboard{
    background-color: $white;
  }

  .container-search{
    background-color: $white;
  }

  .nav-dashboard{
    ul{
      background-color: $white;
    }

    li{
      color: $blue;
    }
    &.mobile ul li{
      background-color: $light-white;
    }
  }

  .title-section{
    color: $blue;
    i{
      color: $blue;
    }
  }

  .navigation-dots{
    background-color: rgba(208,209,218,.3)
  }

}

.exploration:not(.light) .dashboard{
  .header-dashboard{
    background-color: $blue;
  }

  .container-search{
    background-color: $blue;
  }

  .nav-dashboard{
    ul{
      background-color: $blue;
    }
    &.mobile ul li{
      background-color: $light-blue;
    }
  }

  .title-section{
    color: $white;
    i{
      color: $white;
    }
  }

}

.dashboard {
  overflow-y: auto;
  width: 100%;
  max-height : 100%;

  &.filters-opened {
    @media screen and (min-width: #{$md-screen}){
      $filter-width : calc(#{$advanced-filter-width} + 20px);
      .nav-dashboard{
        width: calc(100% - #{$filter-width}) !important;
      }
      .container-loader{
        left : calc(50% + 220px);
      } 
    }    
  }

  .container-export{
    top:0 !important;
  }

  .info-export{
    position: absolute !important;
    display: none;
    width: 100%;
    height: 50px;
    pointer-events: none;

    .export-logo{
      top:4px;
      right:4px;
      padding: 5px !important;
      p{
        font-size: 10px;
      }
    }

    .export-date{
      top:4px;
      left: 4px;
      bottom: auto;
      right: auto;
      line-height: auto  !important;
      height: auto !important;
      padding: 5px !important;
      p{
        font-size: 10px;
      }
    }
  }

  .selected-keywords{
    display: none;
    position: relative;
    left:inherit;
    bottom: inherit;
    background-color: transparent !important;
    text-align: center;
    padding: 5px 10px;
    p{
      position: relative;
      display: inline;
      font-size: $small;
      font-family: "Montserrat-SemiBold";
      color : $primary-color;
      &.exclude{
        color : #ff0000;
      }
      &.search{
        color : #9bb754;
      }
      &:not(:first-child){
        margin-left: 10px;
        &:after{
          content:"";
          position: absolute;
          left:-7px;
          top:9px;
          display: block;
          width: 3px;
          height: 3px;
          border-radius: 50%;
          background-color: $primary-color;
          transform: translateY(-50%);
        }
      }
    }
  }


  .nav-dashboard{

    .container-search{
      @include topbarView()
    }

    >ul {
      width: 100%;
      li{
        cursor: pointer;
        font-family: "Montserrat-SemiBold";
        font-size: $medium;
      }

    }

    // MENU BELOW 720px
    &.mobile {
      >ul{
        padding: 0 0 35px 0;
      }
      >ul li{
        list-style: none;
        text-transform: uppercase;
        margin: $margin-sm;
        padding: $margin-md;
        border-radius: 8px;
      }
    }

    // MENU ABOVE 720px
    &:not(.mobile) {
      $dashboard-left : 20px;
      // $dashboard-left : calc(#{$advanced-filter-width} + 20px);

      position: fixed;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      z-index: 1;
      width: 100%;
      right: 0;

      width: 100%;
      text-align: center;

      transition: all .4s ease-in-out;

      @media screen and (min-width: 1165px){
        display: block;
        // width: calc(100% - #{$dashboard-left} - 80px);
      }

      >ul{
        margin: 0;
        padding: 0;
        @media screen and (min-width: 1165px){
          padding: $margin-md 0 $margin-sm 0;
        }
      }

      >ul li{
        position: relative;
        display: inline-block;
        text-align: center;
        margin: $margin-sm $margin-sm $margin-md $margin-sm;

        @media screen and (min-width: 1165px){
          margin: 0 $margin-sm $margin-sm $margin-sm;
        }

        &:after{
          content : "";
          display: block;
          position: absolute;
          left:50%;
          transform: translateX(-50%) scale(0,1);
          bottom: -5px;
          height: 1px;
          width: 20px;
          background-color: $primary-color;
          transition: all .2s ease-in-out;
        }

        &.inactive{
          pointer-events: none;
          opacity: 0.3;
        }

        &:not(.inactive):hover{
          color:$primary-color;
        }
        &.active{
          color:$primary-color;
        }

        &.active:after{
          transform: translateX(-50%) scale(1,1);
        }

      }

    }

  }

  @include navigationDots();

  .title-section{
    position: relative;
    font-family: "Montserrat-SemiBold";
    font-size: 25px;
    padding: 0 $margin-sm 0 $margin-sm;
    // margin-bottom: 0;
    transition: all .4s ease-in-out;

    >i{
      position: relative;
      top: 4px;
      margin-right: $margin-xs;
    }



    // MOBILE VIEW IS BELOW ON DASHBOARD 720px
    @media screen and (min-width: 720px){
      text-align: center;
      padding: 0 0 $margin-md 0;
      font-size: $titleH1;

      >i{ display: none; }

      &:after{
        content:"";
        position: absolute;
        left:50%;
        width: 200px;
        height: 2px;
        bottom: 0;
        background-color: $disabled-blue;
        transform: translateX(-50%);
      }

    }

    &.title-analyse{
      margin-top: 0px;
    }


  }

  .container-dashboard{
    display: flex;
    max-width: 100%;
    margin: $margin-xs $margin-md 0 $margin-md;
    // padding-bottom: $margin-md;
    flex-wrap: wrap;
    justify-content: center;

    // MOBILE VIEW IS BELOW ON DASHBOARD 720px
    @media screen and (min-width: 720px){
      margin: 40px $margin-md 0 $margin-md;
    }

    @media screen and (min-width: #{$md-screen}){
      max-width: 80%;
      margin: 40px auto 0 auto;
    }

    &.mobile{
      padding-bottom: 80px;
    }



    >div{
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      min-width: 100%;
      @media screen and (min-width: 720px){
        min-width: 50%;
      }
    }

    &.col-3>div{
      min-width: 100%;

      @media screen and (min-width: 720px){
        min-width: 50%;
      }

      @media screen and (min-width: 1200px){
        min-width: 33.3%;
      }

    }
    &.col-4>div{

      min-width: 100%;

      @media screen and (min-width: 720px){
        min-width: 50%;
      }

      @media screen and (min-width: 1200px){
        min-width: 33.3%;
      }

      @media screen and (min-width: 1500px){
        min-width: 25%;
      }

    }


  }

  .container-pie{
    margin: 0 auto;
  }

  .container-pie .legends ul{
    max-height: calc(15 * 20px);
    overflow-y: auto;
    position: relative;
    // right : -20px;
    li{
      display: block;
      // width: 100%;
    }

  }

  @include pie();


}
