@import '../../../scss/variables.scss';

.count{
    position: relative;
    display: inline-block;
    font-family: 'Montserrat-SemiBold';
    margin-left: 3px;
    font-size: $small;
    color:#44466A;
    height: 24px;
    width: 24px;
    border-radius: 100%;
    text-align: center;
    line-height: 24px;

    &:nth-child(1){
      margin-left: $margin-xs;
    }

    &.rub{
      background-color: $rub-color;
    }

    &.select{
      background-color: $select-color;
    }
    &.cumul{
      background-color: $cumul-color;
    }
    &.exclude{
      background-color: $exclude-color;
    }
  }