.mobile-container-tags{
  position: absolute;
  z-index: 2;
  background-color: $white;
  transform: translateX(100%);
  transition: all .4s ease-in-out;
  &.open{
    transform: translateX(0%);
  }

  >div:first-child{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    i{
      position: relative;
      top:3px;
      margin-right: 3px;
    }

    p{
      margin: 0;
      font-family: "Montserrat-SemiBold";
      font-size: $medium;
    }
  }


}


.tags-qes li{
  background-color: $blue;
  &.active{
    background-color: #4E529D;
  }
}

.tags-filters{
  position: relative;
  padding-left: 10px;
  margin-top:0 !important;
  // margin: 0 !important;
  &:after{
    content : "";
    position: absolute;
    left:5px;
    bottom: 3px;
    top: 3px;
    width: 1px;
    background-color: $md-grey;
  }
}

.tags-filters li{
  display: block;
  .tags-filters li{
    display: inline-block;
  }
}

.tags-filters li{
  span{
    background-color: $primary-color;
  }
  &.active span{
    background-color: darken( $primary-color, 10% );
  }
}

.tags-filters li{
  font-family: "Montserrat-Regular";
  font-size: $small;
  color: white;
  list-style-type: none;
  span{
    display: inline-block;
    border-radius: 4px;
    padding: 3px 5px;
    margin: 3px 0 3px 3px;
    cursor: pointer;
  }
}

.tags-qes{
  padding: 0;
  li{
    font-family: "Montserrat-Regular";
    font-size: $small;
    color: white;
    display: inline-block;
    list-style-type: none;
    border-radius: 4px;
    padding: 3px 5px;
    margin: 3px 0 3px 3px;
    cursor: pointer;
  }
}
