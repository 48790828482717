@import "../../scss/variables.scss";
@import "../../scss/_mixins.scss";

.header.light{
    .container-button{
        background-color: $light-grey;
    }
}
  
.header:not(.light){
    .container-button{
        background-color: $blue;
    }
}
  
.header{
    position: relative;
    z-index: 1002;

    .arrow-down{
        cursor: pointer;
        margin-left: 7px;
    }

    @include ToggleLang();

    .boussole{
        cursor: pointer;

    }

    .line{

        display: flex;
        justify-content: space-between;
        padding: 0 $margin-sm;
        box-sizing: border-box;

        .container-btn{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            max-width:100%;
        }

        &.first{
            align-items: center;
            height: 50px;
            background-color: $pure-white;

            .logo-tablet{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                img{
                object-fit: contain;
                max-height:  33px;
                width: auto;
                }
            }

            // img{
            //     width: 35px;
            // }

            .google-custom img{
                width: 20px;
            }

            .google-custom{
                position: relative;
            }

            >ul{
                display: flex;
                align-items: center;
                justify-content: space-between;

                >li{
                position: relative;
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;
                height: 40px;

                &:last-child{
                    padding-right: 0;
                }

                &:not(:last-child):after{
                    content : "";
                    position: absolute;
                    display: block;
                    height: calc(100% - 20px);
                    width: 2px;
                    right:-1px;
                    background-color: $md-grey;
                }
                }

                a{
                    line-height: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-decoration: none;
                }

                img{
                    position: relative;
                    width: 20px;
                    height: 20px;
                    margin: 0 5px 0 0;
                }

                svg{
                    position: relative;
                    top:-1px;
                    margin-right: 8px;
                    width: 10px;
                    path{ fill : $disabled-blue}
                }
               p{
                    font-size: $small;
                    font-family: "Montserrat-SemiBold";
                    color: $text-color;
                }
            }

            .header-profile{
                width: 130px;
                align-items: flex-end !important;
                li:not(:last-child){
                cursor: not-allowed;
                color : $md-grey;
                }
                li:last-child a:hover {
                color:$primary-color;
                }
            }

            .header-help{
                width: 74px;
            }

        }

    }

}

.platform{
    a{
        display: flex;
        align-items: center;
        text-decoration: none
    }

    .logo{
        display: inline-block;
        width:auto !important;
        max-height: 40px;
    }

    .platform-name{
        font-family: "Montserrat-SemiBold";
        color : $blue;
        text-transform: uppercase;
        font-size: $medium;
        text-decoration: none;
        margin-left: $margin-xs;
        @media screen and (max-width: 820px ){
            display: none;
        }
    }
}

.container-left{
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;

    @media screen and (max-width: 500px ){
        .toggle-plateforme label{
            display: none;
        }
    }

    .toggle-plateforme label,
    .container-space label{
        font-family: "Montserrat-SemiBold";
        color : $blue;
        font-size: $medium;
        text-decoration: none;
        margin-left: $margin-xs;
    }

    .container-space{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
  
        label{
          position: relative;
          cursor: pointer;
          border-left: solid 2px $md-grey;
          padding: 3px $margin-xs 3px $margin-xs;
        }
  
        svg{
          color : $md-grey;
          cursor: pointer;
        }
  
      }


    .link-about.only-on-tablet{
        position: relative;
        margin-right: 16px;
        a{
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: $small;
        font-family: "Montserrat-SemiBold";
        color: $text-color;
        text-decoration: none;
        img{
            width: 17px;
            height: 17px;
            margin: 0 7px 0 0;
        }
        }

        &:after{
        content : "";
        position: absolute;
        display: block;
        height: 20px;
        width: 2px;
        right:-8px;
        background-color: $md-grey;
        }

    }

    .toggle-plateforme{
        display: flex;
        margin-right: $margin-xs;
        label{
            cursor: pointer;
        }
    }

    .chevron{
        margin: 0 $margin-xs 0 $margin-xs;
        svg{
        color : $md-grey;
        }
    }

}

.container-right{
    display: flex;
    align-items: center;
    justify-content: space-between;


    ul{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      margin: 0 10px 0 0;


      li{
        position: relative;
        display: inline-flex;
        position: relative;
        list-style: none;
        align-items: center;

        &:after{
          content: "";
          position: absolute;
          right: 0;
          display: block;
          width: 2px;
          height: 26px;
          background-color: $md-grey;
        }

        &.link-about{
            font-size: $small;
            font-family: "Montserrat-SemiBold";
            color: $text-color;
            text-decoration: none;
            img{
              width: 20px;
              height: 20px;
            }
          }

        &.admin img{
            width: 25px;
            height: 25px;
        }

        a{
          display: inline-flex;
          text-decoration: none;
          line-height: 20px;
          img{
            margin-right: $margin-xs;
          }
        }

      }
    }

    img{
      width: 20px !important;
      max-height: 20px;
    }

    p,a{
      font-family: "Montserrat-SemiBold";
      font-size: $regular;
      margin: 0 $margin-xs;
      color: $blue;
    }

    .container-user{
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        cursor: pointer;
      }
    }
}