@import "./variables.scss";
@import "./_mixins.scss";
@import "./list-entries.scss";
@import "./select-platforms.scss";
@import "./dropdown-entries.scss";
@import "./footer.scss";
@import "./toggle.scss";
@import "./documents/documents.scss";
@import "./documents/popup-export.scss";
@import "./filters/filters.scss";
@import "./exploration/exploration.scss";
@import "./intelligence/intelligence.scss";
@import "./popup.scss";
@import "./noMatch.scss";

html, body,#root, .App{
  height: 100%;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;

}

body{
  // position: fixed;
  // height: 100%;
  // -webkit-overflow-scrolling: touch;

  font-family: 'Montserrat-Regular', sans-serif;
}

.App{
  overflow: hidden;
  background-color: $light-grey;
}

h1{
  font-size: $titleH1;
  color: $text-color;
}

h3{
  font-size: $title;
  color: $text-color;
}

h5{
  color: $text-color;
}

.search-field-button-fulltext{
  width: 100%;
  background-color: white;
  color: $primary-color;
  text-transform: inherit;
  border-radius: 0;
  text-align: left;
  padding: 8px 12px;
  font-family: 'Montserrat-SemiBold';
  font-size: 14px;
  min-height: 32px;
  line-height: 16px;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input{
  outline: none;
  font-family: "Montserrat-SemiBold";
}

.exploration:not(.light) .selected-keywords,
.exploration:not(.light) .export-date{
  background-color: lighten(rgba(21,24,69,0.7), 10%);
}

.exploration.light .selected-keywords,
.exploration.light .export-date{
  background-color: darken(rgba(245,245,249,0.7), 2%);
}


.exploration:not(.light) .info-export .export-date{
  background-color: white;
  p{
    color : $blue;
  }
}

.exploration.light .info-export .export-logo,
.exploration.light .info-export .export-date{
  background-color: $blue;
  p{
    color : white;
  }
}

.hide-on-mobile{
  display: inherit;
  @media screen and (max-width: #{$sm-screen}){
    display: none !important;
  }
}

.only-on-mobile{
  display: inherit;
  @media screen and (min-width: #{$sm-screen}){
    display: none !important;
  }
}

.hide-on-tablet{
  display: inherit;
  @media screen and (max-width: #{$md-screen}){
    display: none !important;
  }
}

.only-on-tablet{
  display: inherit;
  @media screen and (min-width: #{$md-screen}){
    display: none !important;
  }
}

///// FOR EXPORT //////
.info-export{
  position: absolute;
  display: none;
  top:0;
  left:0;
  bottom: 0;
  width: 100%;

  .container-btn-docs{
    position: absolute;
    top: 60px;
    right:20px;
    >div{
      margin-left: 0;
      height: 30px;
      font-size: $small;
      line-height: $small;
      >span{
        font-size: $small;
        line-height: $small;
      }
    }
  }


  .export-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    position: absolute;
    top: 20px;
    right:20px;
    border-radius: 8px;
    padding: 5px 10px;
    background-color: white;
    min-height: 20px;
    p{
      font-size: $small;
      line-height: $small;
      height: 12px;
      color: $blue;
      margin: 0 0 0 0;
    }
    img{
      width: 60px;
      object-fit: contain;
    }
  }

  .export-date{
    background-color: transparent;
    position: absolute;
    bottom: 20px;
    right:20px;
    border-radius: 8px;
    padding: 15px;
    p{
      font-size: $small;
      line-height: $small;
      height: 12px;
      font-family: "Montserrat-SemiBold";
      margin : 0;
    }
  }

  .selected-keywords{
    background-color: transparent;
    position: absolute;
    bottom: 20px;
    left:20px;
    // display: none;
    border-radius: 8px;
    padding: 15px;
    p{
      font-family: "Montserrat-SemiBold";
      color : $primary-color;
      margin: 5px 0;
      font-size: $regular;
      &.exclude{
        color : #ff0000;
      }
      &.search{
        color : #9bb754;
      }
      &:first-child{
        margin-top: 0;
      }
      &:last-child{
        margin-bottom: -5px;
      }
    }
  }

}

/////////////////

.separation{
  height: 1px;
  width: 100%;
  background-color: $md-grey;
}

.arrow-down{
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: $blue transparent transparent transparent;
}

.react-tooltip {
  font-family: "Montserrat-SemiBold";
  font-size: $regular !important;
  pointer-events: none !important;
  &:not(.white){
    color : white;
    background-color: $blue !important;
  }
  &.white{
    color : $blue !important;
    background-color: white !important;
    &.place-top {
      &:after {
      border-top-color: white !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
      }
      &:before{
        display: none;
      }
    }

    &.place-bottom {
      &:after {
      border-bottom-color: white !important;
      border-bottom-style: solid !important;
      border-bottom-width: 6px !important;
      }
    }
  }
}

.track-vertical{
  right:0;
  height: 100%;
  // display: none;
  // right:-30px;
  background-color: rgba(255,255,255,.3) !important;
}

.inputField{
  display: flex;
  input{
    height: 44px;
    box-sizing: border-box;
    &.clearable{
      padding-right: 30px;
    }
    &::placeholder {
      font-family: "Montserrat-SemiBold";
      font-style: italic;
      color : #b8b8b8;
      opacity: 1;
    }
  }
  span{
    display: inline-block;
    width: 20px;
    height: 43px;
    min-width: 20px;
    min-height: 43px;
    border-bottom: solid 1px $primary-color;
    i{line-height: 44px; cursor: pointer}
  }
}

button{
  cursor: pointer;
  font-family: "Montserrat-SemiBold";
  background-color: $blue;
  padding: calc(#{$margin-sm}/2) $margin-md;
  border: none;
  color: white;
  text-transform: uppercase;
  border-radius: 5px;
  outline: none;
  &.disabled{
    background-color: $disabled-blue;
  }
}

.error{
  font-size: $small;
  color: $primary-color;
}

.align-right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.align-left{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.container-exploration{
  width: 100%;
}

.search-field{

  // min-width: 300px;
  margin: $margin-sm 0;
  font-family: 'Montserrat-SemiBold';
  font-size: $regular;
  outline: none;
  border-radius: 4px;
  &.header{
    z-index: 11;
  }
  .css-xo7z33-option,.css-fk865s-option{
    color : $text-color;
  }
}

.content-centered{
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  // transition: all .4s ease-in-out;

}

.content-left{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0;
  position: relative;
  box-sizing: border-box;
  width:100%;
}

.toggleView{
  position: absolute;
  background-color: transparent;
  border: none;
  outline: none;
}

.scrollTop{
  position: fixed;
  z-index: 100;
  display: flex;
  width: 27px;
  height: 35px;
  background-color: $primary-color;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
  padding-left: 15px;
  color : white;
  align-items: center;
  justify-content: flex-start;
  &:hover{
    background-color: darken( $primary-color, 10% );
  }

  @media screen and (min-width: #{$sm-screen}){
    width: 40px;
  }

}

.separator{
  width: 100%;
  height: 1px;
  &.blue{
    background-color: $disabled-blue;
  }
}

@include help();
