@import '../../../../scss/variables.scss';
@import '../../../../scss/mixins.scss';

.block-link{
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column-reverse;
    padding: 0;

    &.light{
        h3,h4{
            color : $blue;
        }
        p{
            color : $disabled-blue;
        }
        .container-img{
            @include box-shadow2(light);
        }
    }

    &:not(.light){
        h3,h4{
            color : $white;
        }
        p{
            color : $white;
        }
        .container-img{
            @include box-shadow2();
        }
    }

    @media screen and (min-width: 1024px){
        &.left{
            flex-direction: row;
            .content{
                padding-right: $margin-md;
            }
        }
    
        &.right{
            flex-direction: row-reverse;
            .content{
                padding-left: $margin-md;
            }
        }
    }



    .container-media{
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        font-size: 0px;
        width: 100%;
        
        margin-bottom: $margin-sm;
        background-color: $blue;

        &.image{
            padding-top: calc(100% * 0.27);
        }

        &.video{
            padding-top: 56.25%;
            @media screen and (min-width: 1024px){
                padding-top: calc(56.25% * .6);
            }
            @media screen and (min-width: $md-screen){
                padding-top: calc(56.25% * .5);
            }
        }

        @media screen and (min-width: 1024px){
            width: 40%;
            margin-bottom: 0;
        }
        @media screen and (min-width: $md-screen){
            width: 50%;
        }

        img{
            object-fit: cover;
        }

        video{
            object-fit: contain;
        }

        video,
        img{
            position: absolute;
            top:50%;
            left:50%;
            height: 100%;
            width: 100%;
            transform: translate(-50%,-50%);
        }
    }

    .content{
        width: 100%;
        @media screen and (min-width: 1024px){
            width: 60%;
        }
        @media screen and (min-width: $md-screen){
            width: 50%;
           }

        h4{
            font-size: $medium;
            margin: 0 0 $margin-sm 0;
        }

        h3{
            font-family: "Montserrat-Bold";
            font-size: $titleH1;
            margin: 0 0 $margin-sm 0;
        }

        p{
            font-size: $regular;
            line-height: 20px;
        }

        a{
            font-family: "Montserrat-SemiBold";
            text-transform: uppercase;
            color : $primary-color;
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }

}