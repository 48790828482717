@import '../../../scss/variables.scss';

.tag{
  display: flex;
  padding: 8px 14px;
  background-color: $select-color;
  border-radius: 17px;
  cursor: pointer;
  transition: all .3s ease-in-out;

  &:not(.rub):hover{
    background-color: #4E529D;
    label{
      color:$white;
    }
    .close:before,
    .close:after{
      background-color: $white;
    }
  }

  &.grey{
    background-color:$md-grey;
    &.selected{
      background-color: $select-color;
    }
    &:hover{
      background-color: $select-color;
    }
  }

  &.rub{
    background-color: $rub-color;
    &:hover{
      background-color: darken($rub-color,10%);
    }
  }

  &.cumul{
    background-color: $cumul-color;
    &:hover{
      background-color: darken($cumul-color,7%);
    }
  }

  &.string{
    background-color: #9bb754;
    &:hover{
      background-color: darken(#9bb754,7%);
    }
  }
  &.exclude{
    background-color: $exclude-color;
    &:hover{
      background-color: darken($exclude-color,7%);
    }
  }

  .img-checkbox{
    position: relative;
    top:-1px;
    margin-right: 5px;
  }

  label{
    cursor: pointer;
    font-size: $regular;
    color : $blue;
  }

  .close{
    position:relative;
    width: 14px;
    height: 18px;
    cursor: pointer;
    margin-left: 7px;
    &:after,&:before{
      content: "";
      position: absolute;
      display: block;
      width: 14px;
      height: 1px;
      top:50%;
      border-radius: 2px;
      background-color: $blue;
      transform-origin: center;
    }

    &:after{
      transform: translate(0,-50%) rotate(45deg);
    }

    &:before{
      transform: translate(0,-50%) rotate(-45deg);
    }



  }

}
