@import '../../../../scss/variables.scss';
@import '../../../../scss/mixins.scss';

.block-shortcut{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 8px;
    background-position: center;
    background-size: cover;
    overflow: hidden;
    max-height: 280px;
    padding-top: 50px;
    box-sizing: border-box;
    height: 100%;
    cursor: pointer;

    &.light{
        @include box-shadow2(light);
    }

    &:not(.light){
        @include box-shadow2();
    }

    .mask{
        overflow: hidden;
        margin: $margin-sm;
    }

    &:hover .gradient{
        transform: translateY(-100px);
    }

    .picto{
        position: absolute;
        z-index: 1;
        top:12px;
        left:$margin-sm;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        background-color: rgba(2, 5, 46, 0.6);
        border-radius: 9px;

        img{
            max-width: 25px;
            max-height: 25px;
            width: 100%;
        }    
    }

    .gradient{
        position: absolute;
        top:0;bottom: -100px;
        left:0;right:0;
        transform: translateY(0);
        transition: all .3s ease-in-out;
        background: linear-gradient(180deg, rgba(2, 5, 46, 0) 0%, #02052E 70% , #02052E 100%);
    }

    .content{
        position: relative;
        z-index: 1;
        box-sizing: border-box;
        transition: all .3s ease-in-out;
        h5{
            color : $white;
            font-family: "Montserrat-SemiBold";
            font-size: $medium;
            margin: 0 0 $margin-sm 0;
        }
    
        p{
            color : $white;
            font-family: "Montserrat-Regular";
            font-size: $regular;
            margin: 0;
        }
    }

}