@import '../../../../scss/variables.scss';
@import '../../../../scss/_mixins.scss';

.sso{
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (min-width : $sm-screen){
    flex-direction: row;
  }

  >div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: $margin-sm;
    box-sizing: border-box;

    &:first-child{
      flex: 1;
    }

    @media screen and (min-width : $sm-screen){
      width:50%;
    }
  }

  .container{
    display: flex;
    flex-direction: column;

    h2{
      font-family: "Montserrat-SemiBold";
      font-size: $titleH1;
      line-height: 40px;
      color: #461c7a;
      margin-bottom: $margin-sm;
    }

    a{
      display: block;
      margin: $margin-xs 0;
      width: 200px;
      button{
        width: 100%;
        height: 36px;
        border-radius: 18px;
        background-color: #461c7a;
      }
    }
  }

  .image-container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #3b215b;

    @media screen and (min-width : $sm-screen){
      height: 100vh;
    }

    img{
      max-width: 200px;
      width: 100%;
      @media screen and (min-width : $sm-screen){
        max-width: 350px;
      }
    }
  }
}
