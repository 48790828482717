.exploration.light .wordscloud{
  @include wordcloud("light");
  background-color: $white;
  .container-search .global-search .search-field>div{
    background-color: $white;
  }

}

.exploration:not(.light) .wordscloud{
  @include wordcloud("");
  background-color: $blue;
  .container-search .global-search .search-field>div{
    background-color: $blue;
  }

}

@media screen and (max-width : $sm-screen){
  .container-export.wordscloud-export {
    display: none;
  }
}

.wordscloud-export{
  top:90px !important;
}


.wordscloud{
  display: flex;
  align-items: center;
  justify-content: center;

  &.filters-opened {
    @media screen and (min-width: #{$md-screen}){
      $filter-width : calc(#{$advanced-filter-width} + 20px);
      .container-search{
        width: calc(100% - #{$filter-width}) !important;
      }
      .container-loader{
        left : calc(50% + 220px);
      } 
    }    
  }

  .container-search{
    position: absolute !important;
    z-index: 0;
    top:0px;
    right: 0px;
    @include topbarView();
    width: 100%;
  }


  // .container-search{
  //   position: absolute;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   top:23px;
  //   width: 100%;
  //   padding: 0 $margin-sm 0 $margin-sm;
  //   box-sizing: border-box;
  //   @include globalSearch();
  //
  //   @media screen and (min-width: 620px){
  //     padding: 0 $margin-md 0 $margin-md;
  //   }
  //
  //   .global-search{
  //     width: 100%;
  //     margin: 0;
  //
  //
  //
  //     @media screen and (min-width: 800px){
  //       width: 80%;
  //       max-width: 800px;
  //       margin: 0 auto;
  //     }
  //
  //
  //   }
  //
  // }

  .loading{
    opacity: 0.6;
    pointer-events: none;
  }

}
