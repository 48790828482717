.exploration.light .timeline{

  .container-month-lines .month-line .date-line{
    color: $blue;
  }

  .gradient-left{
    background-image: linear-gradient(to right, $white, rgba(250,0,0,0));
  }

  .gradient-right{
    background-image: linear-gradient(to left, $white, rgba(250,0,0,0));
  }

  .container-timeline .timeline-object{

    >div:nth-child(2) p,
    >.container-node div:nth-child(2) p{
      color: $blue;
    }

    .cluster-nodes ul {
      background-color: $light-grey;
      li p{
        // color:white;
      }
    }

    .icon{
      background-color: $primary-color;
    }

  }

}

.content-timeline{
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.timeline{
  
  &.filters-opened {
      $filter-width : calc(#{$advanced-filter-width} + 20px);
  
      @media screen and (min-width: #{$md-screen}){
        .container-search{
          width: calc(100% - #{$filter-width}) !important;
        }
        .container-loader{
          left : calc(50% + 220px);
        } 
      }
  }

  >div:not(.container-search){
    padding-right: 20px !important;
    width: calc(100% - 20px);
  }

  .react-tooltip.place-left{
    margin-left: -20px;
    background-color: white;
    color:$primary-color !important;
  }
  //
  .__react_component_tooltip.type-dark.place-left:after {
    border-left-color: white;
  }

  // .container-search{
    
  //   @include topbarView()
  // }

  .container-search{
    position: absolute !important;
    z-index: 0;
    top:0px;
    right:0;
    @include topbarView();
    width: 100%;
  }


  .help{
    position: relative;
    top:-15px;
    margin-left: 30px;
  }

  .container-scroll{
    position: relative;
    padding: 0 $margin-md;
    .gradient-left,
    .gradient-right{
      position: absolute;
      z-index: 2;
      pointer-events: none;
      width: 30px;
      top: 0;
      bottom: 40px;
    }

    .gradient-left{
      left:30px;
      background-image: linear-gradient(to right, rgba(21,24,69,1), rgba(255,0,0,0));
    }

    .gradient-right{
      right:0;
      background-image: linear-gradient(to left, rgba(21,24,69,1), rgba(255,0,0,0));
    }

    .loading{
      opacity: .5;
      >*{
        pointer-events: none;
      }
    }

  }

  .configurable-pie-loading{
    opacity: 0.6;
    pointer-events: none;
  }

  .container-timeline{
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;

    top:50%;
    transform: translateY(-50%);


    .timeline-object{
      position: absolute;


      .container-node{
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        // justify-content: flex-start;
        width: 180px;
        transition: opacity .4s ease-in-out;
        overflow-y: visible;
        text-decoration: none;
        color : white;
      }

      &.cluster{
        align-items: center;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        // justify-content: flex-start;
        width: 180px;
        transition: opacity .4s ease-in-out;
        overflow-y: visible;
        text-decoration: none;
        color : white;
      }

      &.fadeOut{
        opacity: .2;
        z-index: 1;
        pointer-events: visible;
      }

      div:nth-child(1).icon{
        width: 30px;
        height: 30px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        margin-right: 10px;
        img{
          width: 50%;
        }
      }

      div:nth-child(2){
        width: 180px;
        // padding: $margin-sm 0;
      }

      &.cluster.active{
          width: 220px;
      }

      &:not(.cluster).active .container-node{
          width: 220px;
      }


      &.active{
        opacity: 1;
        z-index: 3;
        height: auto;
        cursor: pointer;

        p{
          pointer-events: none;
          white-space: inherit;
          overflow: inherit;
          text-overflow: inherit;
        }


        div:nth-child(2){
          width: 180px;
          // padding: $margin-sm 0;
        }

        .cluster-nodes{
          display: block;
        }

      }

      .cluster-nodes{
        position: absolute;
        display: none;
        padding: 0;
        margin: 0;


        ul{
          padding: 5px;
          background-color: white;
          border-radius: 5px;
          max-height: 170px;
          overflow-y: auto;
        }

        &:not(.above){
          top: 100%;
        }

        &.above{
          bottom: 100%;
          // transform: translateY(calc(-100% - 60px));
        }

        li{
          list-style: none;
          width: 200px;

          div>p:nth-child(1){
            color: $primary-color;
            margin-bottom: 5px;
            img{
              width: 8px;
            }
          }
          div>p:nth-child(2){
            color: $blue;
            margin-bottom: 10px;
          }
        }
      }

      .number-docs{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        margin-right: 10px;
        border-radius: 15px;
        color:white;
        font-family: "Montserrat-SemiBold";
        font-size: $regular;
        background-color: $primary-color;
      }

      // .image-doc{
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   height: 30px;
      //   width: 30px;
      //   margin-right: 10px;
      //   border-radius: 15px;
      //   background-position: center;
      //   background-size: cover;
      //   img{
      //     width: 20px;
      //   }
      // }

      div:nth-child(2){
        width: 140px;
      }

      h3,p{
        font-size: $small;
      }

      h3{
        color : $primary-color;
        margin-top: 0;
        margin-bottom: 5px;
      }

      p{
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        // white-space: inherit;
        // overflow: inherit;
        // text-overflow: inherit;
      }

    }

    .container-month-lines.fadeOut{
      .month-line{
        opacity: 0.4;
      }
    }

    .month-line{
      position: absolute;
      z-index: -1;
      opacity: 1;
      width: 2px;
      top: 0;
      height: calc(100% - 18px);
      background-color: $md-grey;
      pointer-events: none;
      transition: top .6s ease-in-out, opacity .6s ease-in-out;
      transform: translateX(-50%);
      opacity: 1;
      @include noselect();


      &.january{
        opacity: 1;
        .date-line{
          font-size: $regular;
          font-family: "Montserrat-SemiBold";
          color : $primary-color;
        }
      }


      .date-line{
        position: absolute;
        bottom: 0;
        font-size: $small;
        white-space: nowrap;
        text-align: center;
        transform: translate(-50%,30px);

      }

    }

    .day-line{
      position: absolute;
      z-index: -1;
      opacity: .3;
      width: 1px;
      top: 0;
      height: calc(100% - 32px);
      background-color: white;
      pointer-events: none;
      transition: top .6s ease-in-out;
      transform: translateX(-50%);
      @include noselect();

      &:nth-child(7n+1){
        background: white;
        opacity: 1;
      }


    }

    .week-line{
      position: absolute;
      z-index: -1;
      opacity: .3;
      width: 2px;
      height: calc(100% - 32px);
      top: 0;
      background-color: $disabled-blue;
      pointer-events: none;
      transition: top .6s ease-in-out;
      transform: translateX(-50%);

      @include noselect();

      .date-line{
        position: absolute;
        bottom: 12px;
        font-size: 12px;
        white-space: nowrap;
        text-align: center;
        transform: translate(-50%,calc(100% + 28px));
        b{
          color: white;
        }
      }


      &:nth-child(0){
        background-color: red !important;
        // transform: translate(-50%,calc(100% + 28px));
      }

      &:nth-child(4n-1) .date-line{
        display: block;
      }

    }

  }

}
