.exploration.light .consolidated-vision{
  @include wordcloud("light");
  @include geomap("light");
}

.exploration:not(.light) .consolidated-vision{
  @include wordcloud("");
  @include geomap("");
}

.consolidated-vision{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loader{
    width: 60px;
  }

  .loader-dataviz{
    position: absolute;
    top:0;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 30px
    }
  }

  .container-map.loading,
  .container-wordcloud.loading{
    opacity: 0.6;
    pointer-events: none;
  }


  .container-map{
    padding: $margin-sm;
  }

}
