.popup-container{
  .overlay{
    position: absolute;
    z-index: 999;
    top:0;
    bottom: 0;
    width: 100%;
    background-color: $blue;
    opacity: 0.85;
    transition: all .4s ease-in-out;

    &.out{
      opacity: 0
    }

    &.fadeOut{
      animation-name: fadeOutOverlay;
      animation-delay: 1.2s;
      animation-duration: .4s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }
  }

  .popup{
    position: fixed;
    z-index: 999;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: white;
    border-radius: 8px;
    padding: 35px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 550px;
    transition: all .8s cubic-bezier(0.235, 0.740, 0.325, 1.3);

    &.out{
      top:-40%;
    }

    &.fadeOut{
      animation-name: slideUp;
      animation-delay: .8s;
      animation-duration: .5s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(.47,-0.23,.91,-0.25);
    }

    .content-popup{
      width: 100%;
    }

    .loader{
      position: relative;
      top:1px;
      width: 25px;
      margin: 5px 5px 0 5px;
    }

    .button-close{
      position: absolute;
      top:-18px;
      right:-18px;
      width: 36px;
      height: 36px;
      border-radius: 18px;
      background-color: $primary-color;
      box-shadow: 0 4px 12px 0 rgba(208,2,37,.3);
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      &:hover{
        background-color: darken($primary-color, 5%);
      }

      .dash{
        position: absolute;
        height: 2px;
        width: 7px;
        background: white;
        transform-origin: right;
        transition: all .4s ease-in-out;
        &:nth-child(1){
          transform: translate(-50%) rotate(45deg);
        }
        &:nth-child(2){
          transform: translate(-50%) rotate(135deg);
        }
        &:nth-child(3){
          transform: translate(-50%) rotate(225deg);
        }
        &:nth-child(4){
          transform: translate(-50%) rotate(315deg);
        }
      }

      &.fadeOut{
        .dash{ opacity: 0}
        animation-name: fadeOutCircle;
        animation-delay: .3s;
        animation-duration: .5s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;

        .dash:nth-child(1){
          transform: translate(-400%, -1200%) rotate(45deg);
        }
        .dash:nth-child(2){
          transform: translate(400%,-1200%) rotate(135deg);
        }
        .dash:nth-child(3){
          transform: translate(300%,1200%) rotate(225deg);
        }
        .dash:nth-child(4){
          transform: translate(-400%,1200%) rotate(315deg);
        }
      }

    }

    .header-popup{
      display: flex;
      align-items: center;
      // justify-content: space-between;

      >div{
        width: 100%;
      }

      >img{
        display: block;
        width: 100px;
        margin-left: 20px;
      }

      h2{
        font-family: "Montserrat-SemiBold";
        font-size: $titleH1;
        margin: 0;
        text-align: center;
        margin-top: $margin-sm;

      }

      .subline{
        font-family: "Montserrat-SemiBold";
        color: $disabled-blue;
        margin: 5px 0 $margin-md 0;
        text-align: center;

      }

    }


    ul{
      padding: 0;
      li{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        list-style: none;
        font-family: "Montserrat-SemiBold";
        font-size: $regular;
        color: $blue;
        margin: 20px 0;

        &:nth-child(1){
          justify-content: space-between;
        }

        .input-search{
          width: 56%;
          float:left;
          input.disabled{
            opacity: 0.3;
          }
        }

        input{
          margin-left: 10px;
          border: solid 2px $md-grey;
          padding: 10px 5px 10px 10px;
          border-radius: 8px;
          width: calc(100% - 10px);
          box-sizing: border-box;
        }

        .input-search{
          position: relative;
          svg{
            position: absolute;
            right:10px;
            top:12px;
            &.active{
              cursor: pointer;
            }
          }
        }
      }

      li .dot{
        display: table-cell;
        vertical-align: middle;
        width: 25px;
        height: 25px;
        border-radius: 15px;
        background-color: $primary-color;
        color: white;
        margin-right: $margin-sm;
        text-align: center;
        line-height: 23px;
        box-shadow: 0 4px 12px 0 rgba(208,2,37,.3);
      }

    }

    .list-shortcut{
      display: block;
      width: 100%;
      margin-top: $margin-sm;
      .loader{
        display: block;
        margin: $margin-sm auto;
        width: 30px;
      }
      li{
        justify-content: space-between;
        width: 100%;
        flex-wrap: nowrap;
        margin: 0;

        label{
          white-space: nowrap;
        }

        .search-field{
          margin: 0 0 $margin-sm 0;

          >div{
            background-color: $light-grey;
          }

        }

      }
      // label{
      //   display: inline;
      // }
      >ul{
        li>div{
          width: 70%;
        }
      }
    }

    .container-button{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      button{
        font-family: "Montserrat-SemiBold";
        background-color: $primary-color;
        box-shadow: 0 4px 12px 0 rgba(208,2,37,.3);
      }
    }

  }

  @keyframes slideUp {
    0% {
      top : 50%;
    }

    100% {
      top : -40%;
    }
  }

  @keyframes fadeOutCircle {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes fadeOutOverlay {
    0% {
      opacity: 0.85
    }

    100% {
      opacity: 0
    }
  }

}
