@import '../../../scss/_mixins.scss';
@import '../../../scss/variables.scss';

.login{
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background-color: white;

  @media screen and (min-width : $sm-screen){
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
    flex-direction: row;
  }


  .left,.right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:100%;
    height: 100%;
    padding: $margin-sm;

    @media screen and (min-width : $sm-screen){
      width:50%;
    }

    h1{
      font-family: "Montserrat-Bold";
    }

    h3{
      font-family: "Montserrat-SemiBold";
    }

  }

  .left{
    align-items: center;
    background: $blue;
    background-image: url('../../../assets/images/login-intelligencemaker.png');
    background-size: cover;
    background-position: center;
  }

  .container-logo-inspiring{
    height: auto;
    padding: $margin-md 0;
    background: $blue;
    img{
      display: block;
      max-height: 120px;
      margin: 0 auto;
    }
  }

  .right{
    padding: 80px 40px;
    box-sizing: border-box;
    align-items: flex-start;
    max-height: calc(100% - 166px);
    min-height: 460px;

    @media screen and (min-width: $md-screen){
      padding: 80px;
    }

    .col-2{
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      width: 100%;

      ///MAX///
      @media screen and (max-width: $sm-screen){
        >div{
          max-width: 400px;
          width: 100%;
        }
        .inputField{
          input{
            width: 100%;
            min-width: auto !important;
          }
        }
      }

      @media screen and (max-width: $md-screen){
        .inputField input{
          width: 100%;
          min-width: 250px;
        }
      }
      ///////


      @media screen and (min-width: $md-screen){
        flex-direction: row;
        width: auto;
      }

      .container-logo-imaker{
        img{
          display: block;
          margin-bottom: $margin-md;
          width: 150px;
          @media screen and (min-width: 300px){
            width: 200px;
          }
        }
      }

    }

    img{
      width: 200px;
      margin-bottom: $margin-md;

      @media screen and (min-width: $md-screen){
        margin-left: $margin-sm;
        margin-bottom: 0;
        width: 250px;
      }
    }

    h1,h3{
      color: $text-color;
    }

    h1{
      margin: 0 0 5px 0;
      font-size: 50px;
    }

    h3{
      font-size: $medium;
      margin-top: 0;
    }

    input{
      font-size: $regular;
      padding: $margin-sm 0;
      border: none;
      border-bottom: solid 1px $primary-color;
      margin: 0 0 $margin-sm 0;
      min-width: 200px;
      width: 70%;
      background: white;
    }

    button{
      margin-top: $margin-md;
    }

  }

}
