@import '../../../scss/variables.scss';
@import './home-mixins.scss';

.home{
  max-height: calc(100% - 70px);
  height: 100%;
  overflow-y:auto;
  box-sizing: border-box;
  margin-top: 70px !important;
  min-height: inherit;

  @media screen and (min-width: 1024px){
    overflow:hidden;
  }

  .container-search{
    top:50px;
    position: fixed;
  }

  &:not(.light) .container .container-block{
    @include block-title();
  }

  &.light .container .container-block{
    @include block-title(light);
  }

  .container{
    display: flex;
    flex-direction: column-reverse;

    @media screen and (min-width: 1024px){
      flex-direction: row;
    }

    .container-block{
      overflow-y: auto;
      width: 100%;
      box-sizing: border-box;
      box-sizing: border-box;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      &.fullwidth{
        padding : $margin-sm $margin-md $margin-lg $margin-md
      }

      &:not(.fullwidth){

        @media screen and (min-width: 1024px){
          .row{
            &.first-block-link{
              margin-top: 9px;
            }
            &.first-block-tags{
              margin-top: 5px;
            }
          }

          .container-block-filter.first-block-filter,
          .container-block-shortcut.first-block-shortcut{
            margin-top: 9px;
          }
        }



        .row,
        .container-block-shortcut,
        .container-block-filter{
          padding: $margin-sm $margin-md $margin-md $margin-md;
        }
        @media screen and (min-width: 1024px){
          .row,
          .container-block-shortcut,
          .container-block-filter{
            padding: $margin-sm $margin-sm $margin-md $margin-md;
          }
        }
      }

      @media screen and (max-width: 1024px){
        .container-block-filter ul{
          display: block !important;
          li:not(:last-child){
            margin-bottom: $margin-md;
          }
        }
      }


      .container-block-shortcut,
      .container-block-filter{

        >ul{
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-auto-rows: 1fr;
          grid-column-gap: $margin-md;
          grid-row-gap: $margin-md;

          margin: 0;
          padding: 0;
          width:100%;

          @media screen and (min-width: 1024px){
            grid-template-columns: repeat(2, 1fr);
          }

          @media screen and (min-width: #{$lg-screen}){
            grid-template-columns: repeat(3, 1fr);
          }

          @media screen and (min-width: 1920px){
            grid-template-columns: repeat(4, 1fr);
          }

          .block{
            display: inline-block;
            align-self: stretch;
            width: 100%;
            box-sizing: border-box;
          }
        }
        
      }

    }
  }


}
