.exploration.light .about{
  h1,
  span *{
    color : $text-color;
  }

}

.about{
  position: relative;

  .backward{
    font-family: "Montserrat-SemiBold";
    position: absolute;
    display: inline-block;
    left : 25px;
    top: 20px;
    color : $primary-color;
    cursor: pointer;
    img{
      position: relative;
      top: 4px;
    }
  }
}

.container-about{
  overflow-y: auto;
  max-height: 100%;

  h1{
    position: relative;
    font-family: "Montserrat-Regular";
    color : $white;
    font-size: $titleH1;
    padding-bottom: $margin-xs;
    display: inline-block;
    margin-bottom: $margin-md;
    &:after{
      content : "";
      position: absolute;
      left:0;
      bottom: 0;
      display: block;
      height: 2px;
      width: 100%;
      background-color: $primary-color;
    }
  }

  p{
    font-family: "Montserrat-Regular";
    color : $white;
    font-size: $regular;
    line-height: 18px;
  }

  .container{
    width: 100%;
    box-sizing: border-box;
    padding: 0 $margin-md;
    margin: 40px auto 70px auto;

    @media screen and (min-width: $sm-screen){
      margin: 0 auto 0 auto;
      padding: 0 $margin-md;
      width: 85%;
    }

    @media screen and (min-width: $md-screen){
      width: 65%;
    }

    .content-about{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;

      @media screen and (min-width: $sm-screen){
        justify-content: center;
      }

      >div{
        width: 100%;
        @media screen and (min-width: $sm-screen){
          width: 78%;
        }
        p:first-child{
          margin-top: 0;
        }
      }

      img{
        display: none;
        width: 100%;
        // max-width: 200px;
        margin-right: $margin-md;
        margin-top: 100px;
        @media screen and (min-width: $sm-screen){
          display: block;
          width: calc(22% - #{$margin-md});
          // max-width: auto;      
        }

      }
    }


  }

}
