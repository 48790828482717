.list-entries-overlay.light{
  background-color: rgba(255, 255, 255, 0.85);
  +.list-entries{
    @include box-shadow;
  }
}

.list-entries-overlay:not(.light){
  background-color: $blue;
  opacity: 0.85;
}

.list-entries-overlay{
  position: absolute;
  z-index: 101;
  width: 100%;
  top:$header-height;
  bottom: 0;
  bottom: 0;
  border-radius: 20px;
}

.list-entries{
  position: absolute;
  z-index: 1000;
  top: $header-height;
  bottom: 0;
  left: 0;

  background-color: #FFFFFF;
  width: 100%;
  max-width: 320px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all .4s ease-in-out;
  transform: translateX(-102%);

  @media screen and (min-width: $md-screen){
    transform: translateX(calc(-100% - 20px));
    top: calc(#{$header-height} + 20px);
    left: 20px;
    bottom: 20px;
    border-radius: 15px;
    min-width: auto;
  }

  &.open{
    transform: translateX(0);
  }

  .title-menu{
    display: block;
    font-family: "Montserrat-Bold";
    font-size: $regular;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $blue;
    margin: $margin-sm 0;
  }

  .container-overflow{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow-y: auto;
    padding: $margin-sm;
    box-sizing: border-box;
  }

  .container-overflow>div:first-child>p{
    font-family: "Montserrat-SemiBold";
    font-size: $medium;
    margin-top: 0;
  }

  .container-overflow>div:first-child>ul{
    margin-top: 0;
    padding: 0;
    li:not(.container-dropdown){
      position: relative;
      list-style-type: none;
      font-family: "Montserrat-SemiBold";
      font-size: $regular;
      margin: $margin-xs 0;
      padding: $margin-sm;
      box-sizing: border-box;
      width: 100%;
      border-radius: 4px;
      cursor: pointer;
      transition-property: color, background-color;
      transition-duration:  .3s;
      transition-timing-function: ease-in-out;

      &.opened{
        margin: $margin-xs 0 0 0;
        border-radius: 4px 4px 0 4px;
      }


      &.opened:not(.selected){
        background-color: #e3e3e3 !important;
      }

      &.selected{
        background-color: $primary-color;
        color: $white;
      }
      &:not(.selected){
        background-color: #F3F3F3;
        color: $text-color;

        &:hover{
          background-color: #e3e3e3 !important;
        }

      }

      .dropdown-indicator{
        position: absolute;
        right: 10px;
        top:50%;
        display: block;
        transform: translateY(-50%);
      }

    }

    li.container-dropdown{
      position: relative;
      margin: 0;
      padding-left: 10px;
      &::marker {
        opacity: 0;
        color: $white;
        font-size: 0;
        display: none !important;
      }
      ul {
        padding: 0 0 $margin-xs 0;
        li{
          list-style-type: none;
          margin-top: 0;
          margin-bottom: 0;
          cursor: pointer;
          &:not(:first-child),
          &:not(:last-child){
            border-radius: 0;
          }

          &:not(:last-child){
            border-bottom: solid 1px $white;
          }

          &:last-child{
            border-radius: 0 0 4px 4px;
          }

        }
      }
    }

  }

  .container-bottom{
    padding: $margin-sm 0 0 0;
    @include ToggleLang();

    >ul{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0;
      margin: $margin-xs 0 0 0;

      >li{
        position: relative;
        list-style: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 20px;
        &:not(:first-child):after{
          content : "";
          position: absolute;
          top:4px;
          display: block;
          height : 20px;
          width: 2px;
          left: -12px;
          background-color: $md-grey;
        }

        &:not(:last-child){
          padding-right: 20px;
        }

        &.google-search{
          img{
            position: relative;
            top:5px;
            width: 17px;
            height: 17px;
          }
        }

        &.admin a,
        &.link-about a{
          font-size: $small;
          font-family: "Montserrat-SemiBold";
          color: $text-color;
          text-decoration: none;
          img{
            position: relative;
            top:4px;
            width: 17px;
            height: 17px;
            margin-right: 5px;
          }
        }

      }
    }


    >div:nth-child(2){
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: $margin-xs 0 $margin-sm 0;

      .react-toggle{
        margin-bottom: 0;
      }

      .react-toggle-track{
        background: $md-grey;
      }

      .react-toggle-thumb{
        border: 1px solid $blue;
        background: $light-grey;
      }

    }

    .container-user{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      >*{
        display: inline-block;
        margin: 0 $margin-xs 0 0;
      }
    }
  }

}
