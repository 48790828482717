@import '../../../../scss/variables.scss';
@import '../../../../scss/mixins.scss';
@import '../home-mixins.scss';

.block-tags{

    @include block-title;
    // padding: $margin-sm 0 $margin-md 0;

    &:not(.light){
        @include block-title();
        ul{
           @include box-shadow2();
        }
    }

    &.light{
        @include block-title(light);
        ul{
            @include box-shadow2(light);
        }
    }

    ul{
        margin:0;
        padding: 12px $margin-sm;
        background: rgba(251, 251, 254, 0.95);
        border-radius: 9px;
        li{
            display: inline-block;
            padding: 3px 0;
            >div{
                margin-right: 6px;
            }
        }
    }
}