@import '../../../scss/variables.scss';

.exploration-nav.light{
    .react-tooltip:after{
        border-bottom-color: #222 !important;
    }

    ul li:not(:last-child):after{
        background-color: #DEDFEA;
    }
}

.exploration-nav:not(.light){
    .react-tooltip:after{
        border-bottom-color: #222 !important;
    }

    ul li:not(:last-child):after{
        opacity: .3;
        background-color:#DEDFEA;
    }
}

.exploration-nav{
    display: flex;
    align-items: center;
    justify-self: start;
    padding: 0 5px;
    label{
        color : $primary-color;
        white-space: nowrap;
        svg{
            position: relative;
            top:2px;
            left:-1px;
        }
    }
    ul{
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-self: start;
        li{
            position: relative;
            display: flex;
            align-items: center;
            justify-self: start;
            list-style-type: none;
            padding: 0 8px;
            height: 28px;

            &:not(:last-child):after{
                content : "";
                position: absolute;
                right:0;
                display: block;
                height: 100%;
                width: 1px;
            }

            &:last-child{
                padding-right: 0;
            }
        
            a.active svg *{
                fill : $primary-color !important;
            }
         }
    }
}