@import '../../../scss/variables.scss';

.container-btn-filters{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  max-width:100%;

  &.with-background{
    background-color: $light-grey;
    height: 50px;
    padding: 0 $margin-sm;
    border-radius: 8px;
    margin-right: $margin-sm;
  }
  
  &.light-background{
    border : 1px solid $primary-color;
    height: 38px;
    padding: 0 $margin-sm;
    border-radius: 8px;
    margin-right: $margin-sm;
    box-shadow: 0px 3px 15px rgba(255, 125, 103, 0.3);

    &.light{
      background-color: #F6E9EA;
      
    }

    &:not(.light){
      background-color: $light-blue ;
      .advanced-filter{
        color : white;
      }
    }

    .advanced-filter{
      font-family: "Montserrat-Regular";
      font-size: $regular;
      text-decoration: underline;
    }

  }

  &.glow{
    justify-content: center;
    background: $primary-color;
    box-shadow: 0px 3px 15px rgba(255, 125, 103, 0.49);
    border-radius: 8px;
    padding: 0 $margin-sm;
    height: 50px;
    max-width: 250px;
    width: 100%;


    .advanced-filter{
      display: block;
      color: $white;
    }
  }

  .advanced-filter{
    display: none;
    font-family: "Montserrat-SemiBold";
    color : $blue;
    padding: 5px 0;
    overflow: hidden;
    cursor: pointer;
    white-space: nowrap;
    max-width:100%;
    @media screen and (min-width: $md-screen ){
      display: block;
    }

    label{
      cursor: pointer;
    }

  }

  .btn-filters{
    position: relative;
    top:3px;
    padding: 7px;
    margin-right: 0px;
    cursor: pointer;
    color: $disabled-blue;
    font-size: 20px;

    .count-filters{
      position: absolute;
      top: 0px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-color;

      transform: translate( 0px , 0px);
      min-width: 16px;
      min-height: 16px;
      width: 16px;
      height: 16px;
      border-radius: 8px;
      text-align: center;
      p{
        margin:  0;
        color : $white;
        font-family: "Montserrat-SemiBold";
        font-size: 10px;
      }
    }
  }


}
