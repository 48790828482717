.inspiring-contents{
  position: fixed;
  z-index: 104;
  top: $header-height;
  bottom: 0px;
  right : 0px;
  width: 100%;

  box-sizing: border-box;
  background-color: $light-grey;
  overflow: hidden;

  @media screen and (max-width: $sm-screen){
    transition: all .4s ease-in-out;
    transform: translateX(100%);

    &.open{
      transform: translateX(0%);
    }
  }

  @media screen and (min-width: $sm-screen){
    top: calc(#{$header-height} + 20px);
    bottom: 20px;
    right : 20px;
    width: 50%;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.5);
    border-radius: 15px;
  }

  @media screen and (min-width: $md-screen){
    width: 55%;
  }


  .backyard{
    padding: 0 0 $margin-sm 0;
    i{
      position: relative;
      top:4px;
      margin-right: 5px;
    }
  }

  p{
    font-size: $small !important;
  }

  .loader{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 30px;
  }

  .content{
    // margin: $margin-sm 0;
    padding: $margin-sm $margin-md;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;

    .loaded{
      text-align: center;
      color: $primary-color;
      font-family: 'Montserrat-SemiBold';
    }
  }

  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-sm;

    h2{
      font-family: 'Montserrat-SemiBold';
      color:$text-color;
      font-size: $medium;
      margin: 0;
    }

  }


  .filters-inspiring-contents{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0;
    min-width: calc(100% + 15px);

    li.filter-rub{
      position: relative;
      font-size: $regular;
      list-style-type: none;
      color: $text-color;
      font-family: 'Montserrat-Regular';
      white-space: nowrap;
      margin: 0 20px 5px 0;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
      &.active{
        color: $primary-color;
      }
      &:not(:last-child):after{
        content : "";
        position: absolute;
        top:calc(50% + 1px);
        right: -13px;
        display: block;
        width: 3px;
        height: 3px;
        border-radius: 1.5px;
        background-color: $text-color;
        transform: translate(-50%,-50%);
      }
    }
  }

  .list-docs{

    display: grid;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: $margin-sm 0;
    grid-gap: 10px;

    @media screen and (min-width: $xs-screen){
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: $sm-screen) and (max-width: 970px){
      grid-template-columns: repeat(1, 1fr);
    }

    @media screen and (min-width: $md-screen){
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: $lg-screen){
      grid-template-columns: repeat(4, 1fr);
    }

    >div{
      cursor: pointer;
      width: 100%;
    }

    >div .image-doc{
      position: relative;
      width: 100%;
      padding-top: 70%;
      background-position: center;
      background-size: cover;
      // $disabled-blue -> opacity 0.15
      background-color: rgba(110,112,124,0.15);

      p{
        width: 100%;
        text-align: center;
        white-space: nowrap;
        color: rgba(110,112,124,0.25);
        font-size: $small !important;
      }

      &.empty{
        display: flex;
        align-items: center;
        justify-content: center;

      }
      >img{
        position: absolute;
        top:50%;
        left:50%;
        width: 30px;
        transform: translate(-50%,-50%);
      }
      >p{
        position: absolute;
        top:40%;
        left:50%;
        font-family: "Montserrat-SemiBold";
        font-size: $small;
        color : $disabled-blue;
        transform: translateX(-50%);
      }
    }

  }



}
