@import '../../../scss/variables.scss';
@import '../../../scss/_mixins.scss';

.tooltip-pinup{
  max-width: 300px;
}

.tags-panel{
  position: absolute;
  z-index: 104;
  top: 50px;
  bottom: 0;
  left:0;
  background-color: $light-grey;
  border-radius: 15px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  @include box-shadow();

  animation-name: slideInPanel;
  animation-duration: .5s;

  @keyframes fadeInPanel {
    0% {
      opacity : 0;
    }

    100% {
      opacity : 1;
    }
  }

  @keyframes slideInPanel {
    0% {
      transform : translate3d(100%,0,0);
    }

    100% {
      transform : translate3d(0,0,0);
    }
  }

  @media screen and (min-width: $sm-screen){
    animation-name: fadeInPanel;
    animation-duration: .5s;
    top: calc(#{$header-height} + 58px + 20px);
    left: calc(#{$advanced-filter-width} + 50px);
    bottom: inherit;
    max-height: calc(100% - (#{$header-height} + 98px));
    border-radius: 15px;
    max-width: 800px;

    &.list-opened{
      width: calc(100% - (#{$advanced-filter-width} + 72px));
      @media screen and (min-width: #{$md-screen}){
        width: calc(100% - (#{$advanced-filter-width} + 150px))
      }
    }

    &:not(.list-opened){
      width: auto;
    }

  }
  @media screen and (min-width: #{$md-screen}){
    top: calc(#{$header-height} + 20px);
    left: calc(#{$advanced-filter-width} + 40px);
    max-height: calc(100% - (#{$header-height} + 40px));
  }

  @include close();
  .close{
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .tooltip-filter-selection-help{
    max-width: 400px;
  }

  .help{
    position: absolute;
    top: 13px;
    right: 40px;
    width: 19px;
    height: 19px;
    cursor: pointer;
  }


  >div{
    overflow-y: auto;
    max-height: 100%;
    padding: $margin-md $margin-md $margin-md $margin-sm;
    box-sizing: border-box;

    @media screen and (min-width: #{$sm-screen}){
      padding: $margin-md $margin-lg $margin-md $margin-md;
    }

    >ul{
      padding: 0;
      margin: $margin-sm 0 0 0;
    }

  }

  .group-filter{

    &:not(:first-child){
      margin-top: $margin-sm;
    }

    .label-tag-filter{
      >.img-checkbox{
        margin-right: 5px;
      }
    }


    >label{
      position: relative;
      display: inline-flex;
      font-family: 'Montserrat-SemiBold';
      color : $text-color;
      text-transform: uppercase;
      cursor: pointer;
      margin-right: $margin-md;
      line-height: 27px;

      &.selected{
        color : $primary-color;
      }

      svg{
        position: relative;
        top:6px;
        margin-right: 10px;
      }

      .title{
        margin-right: 5px;
      }

      &:hover .title{
        text-decoration: underline;
      }

    }

    ul{
      padding: 0;
      margin: $margin-sm 0 0 0;

      li{
        display: inline-block;
        list-style-type: none;
        margin: 5px 0;
        &:not(:last-child){
          margin-right: $margin-xs
        }
      }
    }
  }

  .select-mode{
    padding: 0;

    li{
      display: inline-block;
      color : $blue;
      font-size: $small;
      list-style-type: none;
      padding: 3px 8px;
      border-radius: 13px;
      transition: all .3s ease-in-out;
      cursor: pointer;

      &:last-child{
        padding:0;
        height: 21px;
        img{
          position: relative;
          top:5px;
          width: 18px;
          height: 18px;
        }
      }

      &:not(:last-child){
        margin-right: 8px;
      }

      &.active,
      &:hover{
        color : $white;
      }

      &.cumul{
        border: solid 1px #AA7FD5 ;
        &.active,
        &:hover{
          font-family: 'Montserrat-SemiBold';
          border: solid 1px #816B98;
          background-color: #816B98;
          box-shadow : 0 3px 15px 0 rgba(129, 128, 217,.5);
        }
      }

      &.select{
        border: solid 1px #B6BDFA;
        &.active,
        &:hover{
          font-family: 'Montserrat-SemiBold';
          border: solid 1px #4E529D;
          background-color: #4E529D;
          box-shadow : 0 3px 15px 0 rgba(129, 128, 217,.5);
        }
      }

      &.exclude{
        border: solid 1px#FF7D67;
        &.active,
        &:hover{
          border: solid 1px darken(#FF7D67,7%);
          background-color: darken(#FF7D67,7%);
        }
      }

    }



  }

}
