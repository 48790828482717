@import '../_mixins.scss';
@import "./consolidated-vision.scss";
@import "./geoViz.scss";
@import "./relationships.scss";
@import "./partnerships.scss";
@import "./dashboard.scss";
@import "./wordscloud.scss";
@import "./newspaper.scss";
@import "./timeline.scss";
@import "./analysis-tools.scss";
@import "./help.scss";
@import "./home.scss";
@import "./about.scss";


.exploration.light{
  @include pie("light");
  @include navbar("","light");

  .container-search .css-1wyfq1a,
  .css-1wy0on6>div{
    color: $blue;
  }

  +.filters .react-tooltip{
    &:after{
      border-right-color: #222 !important;
   }
  }

}  


.exploration:not(.light){
  @include pie("");
  @include navbar("","");
  
  .container-search .css-1wyfq1a,
  .css-1wy0on6>div{
    color: $white !important;
  }

  +.filters .react-tooltip{
    background-color: white !important;
    color : $text-color;
    &:after{
      border-right-color: white !important;
   }
  }

}

.exploration{
  position: relative;
  // z-index: 0;
  width: 50%;
  float: left;
  background-color: $blue;
  color:white;
  overflow: hidden;
  transition: all .4s ease-in-out;
  border-radius: 0;

  .hide-navbar .container-navbar{
    transform: translate(-50%, 200%);
    transition: all .4s ease-in-out;

    @media screen and (min-width: #{$sm-screen}){
      transform: translate(200%, -50%);
    }

  }

  .toggle-darkmode{
    position: absolute;
    right:0px;
    top:-60px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    >img{
      width: 20px;
    }
  }

  .help-anna{
    position: absolute;
    display: inline-block;
    z-index: 102;
    left: 50%;
    bottom:0px;
    padding: $margin-sm $margin-md !important;
    transform: translateX(-50%);
    img{
      display: block;
      margin: 0 auto;
      width: 110px;
    }
  }

  // .content-left>.list-filters,
  .content-centered>.list-filters{
    position: fixed;
    z-index: 10;
    top:120px;
    bottom: 20px;
    left: calc(300px + 20px);

    background-color: $light-grey;

    overflow-y: hidden;
    overflow-x: auto;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    padding: $margin-xs 0;
    margin: 0;


    .overlay-scroll{
      position: absolute;
      z-index: 11;
      background: linear-gradient(0.25turn, rgba(29, 34, 97,0), rgba(29, 34, 97,.8));
      top:0;
      bottom: 0;
      right: -40px;
      width: 120px;
      pointer-events: "none";

      &.hide{
        opacity: 0;
      }

    }

    .clear,
    .step-refine{
      font-family: "Montserrat-SemiBold";
      line-height: 16px;
      font-size: $regular;
      margin: 0;
    }

    .clear{
      color: $primary-color;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
      svg {
        margin-right: 10px;
      }
    }

    .step-refine{
      color: $blue;
    }

    .btn-refine{
      // position: absolute;
      height: 30px;
      min-width: inherit;
      bottom: $margin-sm;
      right: $margin-sm;
      margin: 0;
      &:hover{
        text-decoration: underline;
      }
    }

    li{
      padding: 10px $margin-sm !important;
      box-sizing: border-box;
      width: 300px;
      list-style-type: none;




      &.title-section-item{
        font-family: 'Montserrat-SemiBold';
        text-decoration: underline;
        color : $light-blue;
        cursor : pointer;
        >div{
          position: relative;
          top: -1px;
          display: inline-block;
          margin-right: 5px;
        }
        &.selected{
          color : $primary-color;
        }
      }

      &.dropdown-item{
        display: flex;
        align-items:flex-start;
        justify-content: center;

      }

      &.separator{
        display: block;
        height: 1px;
        padding: 0 !important;
        margin: $margin-sm 0 $margin-sm $margin-sm;
        width: calc(300px - (2*#{$margin-sm}));
        background-color : $light-blue;
      }

      img{
        position: relative;
        top:3px;
        margin-right: 5px;
      }

      label{
        display: inline-block;
        font-family: 'Montserrat-Regular';
        font-size: $regular;
        margin-right: $margin-sm;
        line-height: 24px;
        color : $text-color;
        cursor: pointer;

        width: calc(300px - 70px);
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;

        &:hover{
          white-space: inherit;
          text-overflow: inherit;
          overflow: inherit;
        }

        &.selected{
          color : $primary-color;
        }
      }

    }

    &:before{
      content : '';
      position: absolute;
      z-index: 11;
      top:0px;
      left:0;
      right: 0;
      height: 4px;
      background-color: $primary-color;
    }


  }

  .content-centered>div:not(.container-export):not(.container-filter-exploration),
  .content-left>div:not(.container-export):not(.container-filter-exploration){
    padding: 0;

    @media screen and (min-width: #{$md-screen}){
      padding-left: 0;
      transition: padding-left .5s ease-in-out;
      &.filters-opened{
        padding-left: calc(#{$advanced-filter-width} + 20px);
      }
    }

    width: 100%;
    box-sizing: border-box;
  }

  .container-export{
    position: absolute;
    z-index: 1;
    top:20px;
    right :30px;
    transition: all .4s ease-in-out;


    &.light{
      .react-tooltip.place-left{
        background-color: $blue !important;
        color:white !important;
        opacity: 1 !important;
      }
      //
      .__react_component_tooltip.type-dark.place-left:after {
        border-left-color: $blue;
      }
    }

    &:not(.light){
      .react-tooltip.place-left{
        background-color: white;
        color:$primary-color !important;
        opacity: 1 !important;
      }
      //
      .__react_component_tooltip.type-dark.place-left:after {
        border-left-color: white;
      }
    }



  }

  .export-viz{

    background-color: white;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    cursor: pointer;
    margin-left: $margin-sm;

    svg * {
      fill:$primary-color;
    }

    &.light{
      background-color: $primary-color;
      svg * {
        fill:white
      }
    }

    svg{
      position: relative;
      top:6px;
      left:9px;
      width: 14px;
      height: 14px;
    }
  }

  &.light{
    background-color: $white;
    .title{
      color: $blue;
    }
  }

  &.expand{
    width: 100%;
  }

  &.reduce{
    width: 0%;
  }

  >.title{
    position: absolute;
    font-family: 'Montserrat-SemiBold';
    margin-left: $margin-sm;
  }

  h2{
    position: absolute;
    z-index: 200;
    display: inline-block;
    top: $margin-sm;
    text-align: center;
    color: $primary-color;
    font-family: 'Montserrat-SemiBold';
    font-size: $medium;
    text-transform: uppercase;
    &.centered{
      width: 100%;
      left:50%;
      transform: translateX(-50%);
    }
    &:not(.centered){
      left:20px;
    }
  }

  .tooltips{
    color:$primary-color;
    padding: calc(#{$margin-sm}/2);
    border-radius: 5px;
  }

  .toggleView{
    position: absolute;
    top:$margin-md;
    right: $margin-md;
  }

}
