.partnerships{
  display: flex;
  align-items: center;
  justify-content: center;  

  &.filters-opened{
    $newspaper-filter-width : calc(#{$advanced-filter-width} + 20px);
    .container-search{
      @media screen and (min-width: #{$md-screen}){
        width: calc(100% - #{$newspaper-filter-width}) !important;
      }
    }

    .partnerships-legends{
      left : calc(#{$newspaper-filter-width} + 30px);
    }

    .container-loader{
      left : calc(50% + 220px);
    } 

  }

  .container-search{
    position: absolute !important;
    z-index: 0;
    top:0px;
    right:0;
    @include topbarView();
    width: 100%;
  }

  .line{
    stroke: $primary-color;
    stroke-width : 2px ;
    cursor: pointer;
  }

  .circle{
    cursor: pointer;
  }

  .partnerships-legends{
    position: absolute;
    top: 94px;
    margin-top: 0;
    left: 20px;
    padding: 5px $margin-sm;
    border-radius: 8px;
    background-color: $light-blue;

    @media screen and (min-width: $md-screen){
      left: $margin-md;
    }

    &:not(.active) li:not(.selected){
      opacity : 0.3;
    }

    li{
      font-family: "Montserrat-SemiBold";
      font-size: $regular;
      margin: 5px 0;
      list-style-type: none;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
      &.selected{
        font-family: "Montserrat-Bold";
        text-decoration: underline;
      }
      .dash{
        position: relative;
        top:-3px;
        display: inline-block;
        height: 3px;
        width: 10px;
        margin-right: $margin-sm;
      }
    }
  }

  .container-export{
    top: 94px !important;
    right: 20px !important;

    @media screen and (min-width: #{$md-screen}){
      right: $margin-md !important;
    }
  }

}

.exploration.light{
  .partnerships .container-search .global-search .search-field>div{
    background-color: $white;
  }

  .partnerships .partnerships-legends{
    background-color: $light-white;
    @include box-shadow();
  }
  .partnerships .networkViz{
    // background-color: $blue;
    .circle,.label{
      fill: $blue;
    }

    .circle{
      stroke: rgba(69,70,106,0.3);
      stroke-width: 15px;
      .active{
        stroke: rgba(255,125,103,0.3);
      }
    }
    .line{
      stroke: rgba(69,70,106,0.3);
    }
  }
}

.exploration:not(.light){

  .partnerships .container-search .global-search .search-field>div{
    background-color: $blue;
  }

  .partnerships .partnerships-legends{
    background-color: $light-blue;
  }

  .partnerships .networkViz{
    background-color: $blue;

    .label:not(.dark){
      fill: white;
    }

    .label.dark{
      fill: $blue;
    }

    .circle{
      stroke: rgba(255,255,255,0.3);
      stroke-width: 15px;
      fill: white;
      &.active{
        stroke: rgba(255,125,103,0.3);
      }
    }
    .line{
      stroke: rgba(255,255,255,1);
      // stroke: white;
    }
  }

}
