@import "../../../scss/variables.scss";

// @include dropdown-indicator();

.dropdown-indicator{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  height: 20px;
  width: 20px;
  cursor: pointer;

  &.reverse:after{
    transform: rotate(225deg) ;
  }

  &:not(.reverse):after{
    transform: rotate(45deg) ;
  }

  &:hover:after{
    border-color : $disabled-blue
  }

  &.light{
    &:after{
      content : '';
      position: relative;
      top:-3px;
      width: 6px;
      height: 6px;
      border-right: solid 2px white;
      border-bottom: solid 2px white;
    }
  }

  &:not(.light){
    &:after{
      content : '';
      position: relative;
      top:-3px;
      width: 6px;
      height: 6px;
      border-right: solid 2px $blue;
      border-bottom: solid 2px $blue;
    }
  }


}

.clear-indicator{
  position: relative;
  height: 20px;
  width: 20px;
  &:hover:after,
  &:hover:before{
    background-color :  $disabled-blue
  }

  &.light:after,
  &.light:before{
    background-color:$white;
  }

  &:not(.light):after,
  &:not(.light):before{
    background-color:$blue;
  }

  &:after,&:before{
    content : '';
    position: absolute;
    top:50%;
    left:50%;
    width: 12px;
    height: 2px;
    transform-origin: center;
  }

  &:before{
    transform-origin: center;
    transform: translate(-50%,-50%) rotate(45deg) ;
  }

  &:after{
    transform-origin: center;
    transform: translate(-50%,-50%) rotate(-45deg) ;
  }
}
