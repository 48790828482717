.popup-export-overlay{
  position: absolute;
  z-index: 105;
  background: $light-grey;
  width: 100%;
  height: 100%;
  background-color: $blue;
  opacity: 0.25;
}

.popup-export{
  position: absolute;
  z-index: 105;
  background: $light-grey;
  width: 80%;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  padding: $margin-md;
  border-radius: 15px;
  box-shadow: 2px 2px 10px 0 rgba(0,0,0,.1);

  .no-docs-selected{
    text-align: center;
    margin: 0;
    color: $disabled-blue;
  }

  h3{
    margin-top: 0;
  }

  .title-list{
    display: flex;
    justify-content: space-between;
    p{
      font-family: "Montserrat-SemiBold";
      font-size: $regular;
      margin: 0;
    }
    p:nth-child(2){
      cursor: pointer;
      text-decoration: underline;
      color: $blue;
      &:hover{
        color : $primary-color;
      }
    }
  }

  .list-fields{
    width: 100%;
    max-height: 400px;
    padding: 0;
    margin: $margin-xs 0 $margin-md 0;
    overflow-y: auto;
    li{
      display: inline-flex;
      align-items: center;
      list-style-type: none;
      margin: 7px 0;
      font-size: $regular;
      &.separator{
        display: block;
        width: calc(100% - #{$margin-xs});
        height: 1px;
        background-color: $md-grey;
        margin: $margin-xs 0 $margin-xs 0;
      }
      img{
        position: relative;
        margin-right: $margin-xs;
      }
      p{
        display: inline-block;
        margin: 0;
      }
    }
  }

}
