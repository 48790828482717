@import '../_mixins.scss';

.intelligence{
  position: relative;
  width: 50%;
  float: right;
  overflow: hidden;
  transition: all .4s ease-in-out;

  &.expand{
    width: 100%;
  }

  &.reduce{
    width: 0%;
  }

  .toggleView{
    position: absolute;
    top:$margin-md;
    left: $margin-md;
  }

  .navbar{
    @include navbar("right");
  }

}
