@import '../../../scss/variables.scss';

.btn-documents{
  display: inline-flex;
  align-items: center;
  color : $white;
  margin-left: $margin-sm;
  padding: 5px 8px;
  border-radius: 8px;
  height: 30px;
  line-height: 40px;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;

  &:not(.no-background){
    font-family: "Montserrat-SemiBold";
    background-color: $primary-color;
    color : $white;
    height: 40px;
    box-shadow: 0px 3px 15px rgba(255, 125, 103, 0.499802);
  }

  &.no-background{
    justify-content: center;
    border: solid 1px $primary-color;
    font-family: "Montserrat-Regular";
    color : $primary-color;
    font-size: $regular;
    height: 40px;
    width: 100%;
    max-width: 260px;
    text-align: center;
  }

  @media screen and (min-width: $md-screen){
    font-size: 18px;
  }

  .sub{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    margin: 0 0 0 5px;
    font-family: "Montserrat-Light";
    font-size: 18px;
    line-height: 14px;

    >span{
      font-size: 10px;
      text-transform: lowercase;
    }
  }

  >img{
    max-width: 25px;
    max-height: 25px;
    margin-top: -3px;
  }
}
