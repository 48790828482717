@import './document-view.scss';
@import './inspiring-contents.scss';

.exploration.light+.overlay-documents{
  background-color: rgba(255, 255, 255, 0.85);
}

.exploration:not(.light)+.overlay-documents{
  background-color: $blue;
  opacity: 0.85;
}

.overlay-documents{
  position: absolute;
  z-index: 101;
  top: $header-height;
  width: 100%;
  bottom: 0;
  border-radius: 20px 20px 0 0;
}

.document-loading{
  position: absolute;
  z-index: 102;
  top: calc(#{$header-height} + 50%);
  left: 50%;
  transform: translate(-50%,-50%);
  width: 35px;
  opacity: 0.5;
}

.documents{
  position: absolute;
  z-index: 103;
  right: 0;
  top: $header-height;
  bottom: 0;
  background-color: $light-grey;
  box-sizing: border-box;
  overflow: hidden;
  @include box-shadow();

  @media screen and (min-width: #{$sm-screen}){
    right: 30px;
    top: calc(#{$header-height} + 20px);
    bottom: 20px;
    border-radius: 15px;
  }

  .react-tooltip.place-top{
    background-color: $blue;
    text-align: center;
  }

  .content{
    position: absolute;
    top:0;bottom:0;
    width: 100%;
    overflow-y: auto;
    width: 100%;
    padding: $margin-sm;
    box-sizing: border-box;

    @media screen and (min-width: #{$sm-screen}){
      padding: $margin-md;
    }

    @include close();
    .close{
      position: absolute;
      right: 14px;
      top:10px;
      margin-left: $margin-md;
    }

    @media screen and (min-width: #{$md-screen}){
      .close{
        display: none !important;
      }
    }

  }

  &.noscroll{
    overflow-y: hidden;
  }

  .scrollTop{
    right: 0px;
    bottom: 20px;
    @media screen and (min-width: #{$sm-screen}){
      right: 30px;
      bottom: 70px;
    }
  }

  .title{
    display: flex;
    justify-content: stretch;
    align-items: center;
    margin-bottom: $margin-sm;

    @media screen and (max-width: #{$sm-screen}){
      flex-wrap: wrap;
      margin-top: 0 !important;
    }

    @media screen and (max-width: #{$md-screen}){
      margin-top: $margin-sm;
    }


    h1{
      font-family: 'Montserrat-SemiBold';
      color:$text-color;
      font-size: $title;
      margin-top: 0;
      margin-bottom: 0;
      width: 100%;

      @media screen and (min-width: #{$sm-screen}){
        width: inherit;
      }

    }

    p{
      margin: 0;
    }

    .container-export{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      padding-left: $margin-xs;
      box-sizing: border-box;
      p{
        font-family: 'Montserrat-SemiBold';
        color:$text-color;
        font-size: $regular;
        text-decoration: underline;
        margin-right: $margin-xs;
        cursor: pointer;
        &:hover{
          color: $primary-color;
        }
      }
    }

    .container-export .btn-export{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px $margin-xs;
      background: white;
      // width: 20px;
      height: 20px;
      border-radius: 3px;
      cursor: pointer;

      font-size: $small;
      font-family: "Montserrat-SemiBold";

      @media screen and (max-width: #{$md-screen}){
        margin-right: $margin-sm;
      }

      svg{
        margin-left: $margin-sm;
      }

      &:not(:hover){
        svg:first-child{
          display: block;
        }
        svg:last-child{
          display: none;
        }
      }
      &:hover{
        svg:first-child{
          display: none;
        }
        svg:last-child{
          display: block;
        }
      }

    }

  }


  .loaded{
    text-align: center;
    padding: $margin-sm 0;
    font-family: 'Montserrat-SemiBold';
    font-size: $small;
    color : $md-grey;
  }

  .loader-circle{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 25px;
  }

  .no-docs{
    position: absolute;
    top:47%;
    left:50%;
    text-align: center;
    padding: $margin-sm 0;
    font-family: 'Montserrat-SemiBold';
    font-size: 25px;
    color : $md-grey;
    transform: translate(-50%,-50%);
  }


  @include dropdownFilter();

  .filters-list{
    margin-top: $margin-xs;
    svg{
      margin-right: $margin-xs !important;
    }
  }

  .container-documents-filters{

    >p{
      font-size: $regular;
      font-family: 'Montserrat-SemiBold';
      line-height: 20px;
      @media screen and (min-width: 600px){
        line-height: $regular;
      }
      >span{
        display: inline-block;
      }
      >span>span{
        position: relative;
        padding: 5px 0;
        margin: 0 5px;
        cursor: pointer;

        @media screen and (max-width: 600px){
          margin: 0;
        }

        &:first-child{
          margin-left: 0;
        }

        &:not(:last-child):after{
          content:'';
          position: absolute;
          right:-7px;
          top:50%;
          transform: translate(-50%,-50%);
          display: block;
          width: 2px;
          height: 2px;
          background-color: $blue;
          border-radius: 2px;
        }
        &.selected{
          color: $primary-color;
        }
      }
    }
  }

  .list-documents{
    padding: 0;
    margin: 0;

    &.loading{
      opacity: 0.6;
      pointer-events: none;
    }

    li{
      display: flex;
      align-items: center;
      font-size: $regular;
      list-style-type: none;
      padding: $margin-sm 0;
      border-bottom: solid 1px $md-grey;
      cursor: pointer;

      >div:nth-child(1){
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: #{$sm-screen}){
          >img {display: none;}
        }

        .image-doc{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 45px;
          width: 65px;
          margin-right: $margin-sm;
          background-size:cover;
          background-position: center;
          background-color: $disabled-blue;

          @media screen and (min-width: #{$sm-screen}){
            margin-left: $margin-xs;
          }

          >img { //loader
            width : 20px;

          }
        }

        >p{ display: none }
      }

      >div:nth-child(2){
        width: calc(100% - 65px - #{$margin-sm})
      }

      .extract{
        font-family: 'Montserrat-SemiBold';
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .sub{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 3px 0;
        font-size: $small;
        .society{
          position: relative;
          font-family: 'Montserrat-SemiBold';
          color: $primary-color;
          margin-right: 12px;
          &:after{
            content:'';
            position: absolute;
            right:-9px;
            top:50%;
            transform: translate(-50%,-50%);
            display: block;
            width: 4px;
            height: 4px;
            background-color: $primary-color;
            border-radius: 2px;
          }

        }
        p{
          margin-top: 0;
          margin-bottom: 5px;
        }
        .date{
          width: 100%;
        }
      }

    }
  }


  .notation{
    display: flex;
    margin-right: 5px;
    .dot{
      width: 6px;
      height: 6px;
      border-radius: 4px;
      border: solid 1px $primary-color;
      margin-right: 3px;
      &.active{
        background-color: $primary-color;
      }
    }
  }

  .date{
    font-family: 'Montserrat';
    margin: 0;
    color : $disabled-blue;
    font-size: $small;
  }

  .container-loader{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $margin-md 0;
    .loader{
      width: 80px;
    }
  }

}
