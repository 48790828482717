@import '../../../../scss/variables.scss';
@import '../home-mixins.scss';

.last-content{
  position: relative;
  padding: 0 $margin-md;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  align-self: stretch;
  overflow: hidden;
  transition: all 1s ease-in-out;

  @media screen and (min-width: 1024px){
    max-width: 400px;
    padding: 0 $margin-md 0 $margin-sm;
  }
  // @media screen and  (min-width: 1350px) and (max-width: 1450px){
  //   max-width: 370px;
  // }


  // @media screen and  (min-width: 1450px){
  //   max-width: 400px;
  // }


  .block-title{
    padding-top: 20px;
  }

  .no-documents{
    margin: 0;
    color : $disabled-blue;
    font-family: "Montserrat-SemiBold";
  }

  &.light{
    @include block-title(light);
    .image-doc{
      @include box-shadow2(light);
    }
    li{
      color : $blue;
    }
  }

  &:not(.light){
    @include block-title();
    .image-doc{
      @include box-shadow2();
    }
    li{
      color : $white;
    }
  }

  .list-documents{
    padding: 0;
    margin: $margin-sm 0 0 0;
    li{
      display: inline-flex;
      list-style: none;
      width: 100%;
      cursor: pointer;
      *{
        cursor: pointer;
      }

      &:not(:last-child){
        margin-bottom: 20px;
      }

      label{
        display: block;
        font-family: "Montserrat-Medium";
        font-size: $regular;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-height: 50px;
      }

      .date{
        font-family: "Montserrat-Light";
        font-size: $regular;
        margin: 0;
      }

    }
  }

  .image-doc{
    position: relative;
    width: 70px;
    min-width: 70px;
    height: 70px;
    margin-right: $margin-xs;
    padding-top: 0;
    background-position: center;
    background-size: cover;
    background-color: rgba(110,112,124,0.15);
    overflow: hidden;
    border-radius: 100%;


    // @media screen and (min-width: 620px){
    //   width: 100%;
    //   height: inherit;
    //   min-width : inherit;
    //   padding-top: 70%;
    // }

    >img{
      position: absolute;
      top:50%;
      left:50%;
      width: 30px;
      transform: translate(-50%,-50%);
    }
    >p{
      position: absolute;
      top:40%;
      left:50%;
      font-family: "Montserrat-SemiBold";
      font-size: $small;
      color : $disabled-blue;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }



}
