////// NAVBAR ///////

@mixin noselect() {
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

/////// BREAKPOINTS ////////

@mixin breakpoint($class) {
  @if $class == xxs {
    @media (max-width: 467px) { @content; }
  }

  @if $class == xs {
    @media (min-width: 620px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 1124px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == xl {
    @media (min-width: 1500px) { @content; }
  }

  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
  }
}

//////// GLOBAL SEARCH ///////
@mixin globalSearch(){
  .global-search{
    position: relative;
    min-width: 0;
    width: 100%;

    max-width: 100%;
    display: flex;
    align-items: flex-start;

    @media screen and (min-width : $sm-screen) {
      width: auto;
      min-width: 350px;
    }

    @media screen and (min-width : 1024px) {
      min-width: 470px;
    }

    @media screen and (min-width : $md-screen) {
      min-width: 330px;
    }

    @media screen and (min-width : 1350px) {
      min-width: 470px;
    }

    &.light{

      .react-tooltip:after{
         border-bottom-color: #222 !important;
      }

      .toggle-expand:before,
      .toggle-expand:after{
        background-color: $blue;
      }
    }

    &:not(.light){
      .react-tooltip:after{
        border-bottom-color: #222 !important;
      }
      .toggle-expand:before,
      .toggle-expand:after{
        background-color: $white;
      }
    }

    .icon{
      position: absolute;
      margin-left: 10px;
      cursor: pointer;
      line-height: $header-height;
      width: 20px;
      &.right{
        right: 0;
        transform: translateX(200%);
      }
      &:not(.right){
        transform: translateX(-200%);
      }


    }

    .search-field{
      width: 100%;
      margin: 0 0 10px 0;
      border-radius: 8px 8px 0 0;
      // border : solid 2px $primary-color;
      border-bottom: none;

      .css-151xaom-placeholder{
        padding-left: 10px;
      }

      .css-1wy0on6:hover>div{
        color: $primary-color !important;
      }

    }

    .toggle-expand{
      position: absolute;
      z-index: 11;
      bottom: 2px;
      left:0px;
      right:0;
      height: 12px;
      border-top: 1px solid #898CAD;
      // width: 520px;
      // border-left: solid 2px $primary-color;
      // border-right: solid 2px $primary-color;
      // background-color: $primary-color;
      cursor: pointer;
      transition: background-color .2s ease-in-out;
      border-radius: 0 0 4px 4px;

      &.expanded{
        bottom: -2px;

        &:after{
          top:6px;
          transform: rotate(45deg) translateX(1px);
          transform-origin: right;
        }

        &:before{
          top:6px;
          transform: rotate(-45deg) translateX(-1px);
          transform-origin: left;
        }

      }
      &.disabled{
        cursor: inherit;
        background-color: $md-grey;
      }

      &:not(.expanded){
        &:after{
          top:3px;
          transform: rotate(45deg) translateX(-1px);
          transform-origin: left;
        }

        &:before{
          top:3px;
          transform: rotate(-45deg) translateX(1px);
          transform-origin: right;
        }
      }

      &:after{
        content:"";
        position: absolute;
        left:calc(50% - 3px);
        display: block;
        width: 7px;
        height: 2px;
      }
      &:before{
        content:"";
        position: absolute;
        left:calc(50% - 3px);
        display: block;
        width: 7px;
        height: 2px;
      }
    }
  }
}

/////// TOPBARVIEW ///////

@mixin topbarView(){
  position: relative;
  z-index: 3;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  padding: 23px $margin-sm 0 $margin-sm;
  box-sizing: border-box;
  transition: width .4s ease-in-out;
  @include globalSearch();
  

  &.light{
    background-color: $white;
  }

  &:not(.light){
    background-color: $blue;
  }

  .container-left{
    min-width: 0;
    display: flex;
    width: 100%;
    // max-width: calc(100% - 135px);
    // @media screen and (min-width : $sm-screen) {
    //   max-width: calc(100% - 512px);
    // }
    // @media screen and (min-width: $md-screen){
    //   max-width: calc(100% - 762px);
    // }

  }

  .container-right{
    min-width: min-content;
    // min-width: 0;
    display: flex;
  }

  &.hide{
    display: none;
  }

  @media screen and (min-width: 620px){
    padding: 23px 20px 0 20px;
  }

  @media screen and (min-width: $sm-screen){
    padding: 23px 20px 0 20px;
  }



  @media screen and (min-width: $md-screen){
    padding: 23px $margin-md 0 $margin-md;
  }

  .global-search{
    // max-width: 600px;
    // width: 100%;
  }
}

/////// HELP ///////

@mixin help(){

  .exploration.light{
    .help{
      background-color: $primary-color;
      p{
        color: white;
      }
    }
    .__react_component_tooltip {
      background-color: $blue !important;
      opacity: 1;
      color:white !important;
    }
    .__react_component_tooltip.type-dark.place-left:after {
      border-left-color: $blue !important;
    }

  }

  .__react_component_tooltip { opacity: 1 }

  .help{
    background-color: white;
    height: 30px;
    width: 30px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: $margin-sm;


    p{
      font-family: "Montserrat-SemiBold";
      color:$primary-color;
      margin: 0;
    }
  }
}

/////// CHECKBOX ////////

@mixin checkbox() {
  .field-checkbox{
    display: inline-block;
    margin-bottom: $margin-sm;
    cursor: pointer;
    label{
      line-height: 15px;
    }
    .checkbox{
      line-height: 15px;
      margin-right: 10px;
      display: inline-block;
      vertical-align: bottom;
    }
  }
}

//////// TOGGLE LANG //////

@mixin ToggleLang(){
  .selector-lang{
    padding: 0;
    li{
      display: inline-block;
      list-style-type: none;
      color : $md-grey;
      font-family: "Montserrat-SemiBold";
      &.active{
        color : $text-color;
      }

      &:nth-child(odd){
        margin: 0 3px;
        cursor: pointer;
      }

      &:nth-child(even){
        color : $text-color;
      }

      &:nth-child(odd){
        margin: 0 3px;
      }

    }
  }
}

/////// pie ///////

@mixin pie($view:""){

  @if $view == 'light' {
    .container-pie{
      transition: all .4s ease-in-out;
      background-color: $white;
    }
    .container-pie h3{
      color : $text-color;
    }
    .container-pie .legends li{
      color: $blue;
    }
  } @else {
    .container-pie{
      transition: all .4s ease-in-out;
      background-color: $blue;
    }
    .container-pie h3{
      color : white;
    }
    .container-pie .legends li{
      color: white;
    }
  }

  .container-pie h3{
    text-align: center;
    font-size: $medium;
    font-family: "Montserrat-SemiBold";
    margin-bottom: 0;
    transition: all .4s ease-in-out;
  }

  .container-pie{
    position: relative;
    display: flex;
    flex-direction: column;
    flex : 1 1 46%;
    align-items: center;
    overflow: hidden;

    .container-export{
      position: absolute !important;
      z-index: 0;
      right:0 !important;
    }

    .pie-loading{
      opacity: 0.6;
      pointer-events: none;
    }

    .legends ul .tendance{
      position: relative;
      top:3px;
      height: 15px;
      width: 15px;
      // margin-right: 5px;
      .dot{
        position: relative;
        top:-4px;
        // left:7px;
        display: inline-block;
        width:6px;
        height: 6px;
        border-radius: 3px;
      }
    }

    .legends ul.show li>span{
      white-space: normal;
    }

    .legends ul li>span{
      display: inline-block;
      // background-color: red;
      max-width: 100%;
      padding-right: 5px;
      box-sizing: border-box;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &:hover{
        white-space: normal;
      }
    }

    .arc{
      cursor: pointer;
    }

    >div{
      position: relative;
      .loader{
       position: absolute;
       width: 30px;
       left:50%;
       top:47%;
       transform:translate(-50%,-50%);
     }
    }

  }

  .container-tooltip{
    position: absolute;
    top:0;
    .tooltip{
      display: none;
      pointer-events: none;
      position: absolute;
      z-index: 10;
      background-color: white;
      padding: 3px;
      border-radius: 3px;
      border: solid 1px $text-color;
      transform: translate(-50%,-100%);
      p{
        font-family: "Montserrat-SemiBold";
        color: $text-color;
        font-size: $small;
        margin: 0;
        white-space: nowrap;
      }
    }
  }

  .container-pie .legends{
    display: inline-block;
    padding-bottom: $margin-md;
    overflow-x: hidden;
    overflow: hidden;
    position: relative;
    // left : -20px;
    max-width: 100%;

    &.loading{
      opacity: 0.6;
      pointer-events: none;
    }

    ul{
      position: relative;
      display: inline-block;
      padding-left: 0px;
      margin: 0;
      overflow-x: hidden;
      max-width: 100%;
    }
    li{
      display: flex;
      align-items: flex-start;
      max-width: 100%;
      position: relative;
      list-style-type: none;
      font-family: "Montserrat-SemiBold";
      font-size: $regular;
      line-height: 20px;
      padding-left: 10px;
      box-sizing: border-box;
      cursor: pointer;

      // &:not(.selected){
      //   color : $disabled-blue;
      // }
    }
  }

}

/////// WORDS CLOUD //////

@mixin wordcloud($view){

  #wordscloud.loading{
    opacity: 0.6;
    pointer-events: none;
  }

  #wordscloud{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wordscloud-noData{
      display: flex;
      align-items: center;
      justify-content: center;
  }

  #area{
    cursor: pointer;
    circle:hover{
      fill : darken($primary-color,10%) !important;
    }
    g>line{
      pointer-events: none;
    }
  }

  .wordscloud .word{
    cursor: pointer;
    // transition: all .4s ease-in-out;
    &.selected,&.active{
      fill:$primary-color;
    }
    &.exclude{
      fill : lighten($blue,30%);
    }
    &:hover{
      fill:$primary-color;
    }
  }

  @if $view == 'light' {
    .wordscloud .word{
      fill:$blue;
    }
  } @else {
    .wordscloud .word{
      fill:white;
    }
  }
}

/////// GEO MAP //////

@mixin geomap($view){

  .geomap #map{
    position: relative;

    .list-code{ display: none }
    svg path{
      @include  noselect();
    }
  }

  // .geomap svg .empty{
  //   pointer-events: none;
  // }

  @if $view == 'light' {
    .geomap svg .empty{
      cursor: default;
      fill:$md-grey;
    }
  } @else {
    .geomap svg .empty{
      cursor: default;
      fill:white;
    }
  }
}

////// NAVBAR ///////

@mixin navbar($position:"",$view:""){
  .overlay{
    position: absolute;
    z-index: 3;
    // display: none;
    visibility: hidden;
    top:0;
    right:0px;
    opacity: 0;
    transition: all .3s ease-in-out;

    &.nearby{
      display: block;
      visibility: visible;
      opacity: 1;
      width: 100%;
      @if $position == "right" {
        background-color: rgba(131,134,179,.2);
      }@else{
        background-color: rgba(31,34,79,0.7);
      }

      @if $view == "light" {
        background-color: rgba(255,255,255,.85);
      }

    }
  }

  .container-navbar{
    position: absolute;
    z-index: 10;
    bottom : 0;
    left: 50%;
    transition: all .3s ease-in-out;
    transform: translate(-50%,0%);
    max-width: 100%;

    @media screen and (min-width: #{$sm-screen}){
      top:45%;
      right:30px;
      width: 60px;
      left: inherit;
      transform: translate(0,-50%);
    }

    @media screen and (min-width: #{$md-screen}){
      right:40px;
    }


    &.nearby{
      width: 230px;
    }

    .navbar{
      display: flex;
      align-items: center;
      transition: all .3s ease-in-out;
      overflow: hidden;
      margin-right:35px;

      .container-nav{
        z-index: 10;
        width: 100%;
        overflow: hidden;
        border-radius: 8px 8px 0 0;
        @media screen and (min-width: #{$sm-screen}){
          position: absolute;
          border-radius: 8px;
        }
      }

      &.tablet {
        margin-right: 0;
        border-radius: 8px 8px 0 0;
        box-shadow: 0px 0px 15px rgba(155, 165, 185, 0.8);

        // @include box-shadow();


        .container-nav{
          overflow-x: auto;
        }

        .container-nav ul {

          @media screen and (max-width: #{$sm-screen}){
            flex-direction: row;
          }

          li{
            a{
              pointer-events: inherit;
              padding: 0;
              height: 48px;
              width: 100%;
              max-width: 60px;
            }
          }
        }
      }

      &:not(.tablet) {

        .container-nav ul{

          @if $position == "right" {
            align-items: flex-end;
          }

          li a{
            height: 48px;
            width: 230px;
          }

        }

      }

      @if $view == "light" {
        .container-nav{
          background-color: $light-white;
          @media screen and (min-width: #{$sm-screen}){
            @include box-shadow();
          }
        }
      }@else{
        .container-nav{
          background-color: $light-blue;
        }
      }

      @if $position == "right" {
        right:0;
        justify-content: flex-end;
      }@else{
        left:0;
      }

      .container-nav ul {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 0;
        margin: 0;
        overflow: hidden;
      }

      li{
        list-style: none;
        a{
          position: relative;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          text-decoration: none;
          pointer-events: none;
          white-space: nowrap;

          @if $position == "right" {
            color:$text-color;
            padding: $margin-sm 0 $margin-sm $margin-md;
          }@else{
            color:white;
            padding: $margin-sm $margin-md $margin-sm 0;
          }

          @if $view == "light" {
            color:$text-color;
          }

          span{
            opacity: 0;
            transition: all .3s ease-in-out;
          }

          svg{
            width: 25px;
            margin: 0 $margin-xs;

            @media screen and (min-width: 445px){
              margin: 0 $margin-sm;
            }

            *{
              transition: all .3s ease-in-out;
            }

          }

          &:hover{
            color : $primary-color;
            svg *{
              fill : $primary-color !important;
            }
          }

          @media screen and (min-width: #{$sm-screen}){
            &:before{
              content: '';
              position: absolute;
              top:0;
              display: block;
              width: 5px;
              border-radius: 2px 0 0 2px;
              height: 100%;
              background-color: $primary-color;
              transition: all .3s ease;
              right:-5px;
            }
          }


        }

      }

      &.nearby{
        z-index: 10;
        width: 240px;

        ul{
          z-index: 11;
        }

        a{
          pointer-events: inherit;
        }

        span{
          opacity: 1;
          display: inline-block;
        }
      }
    }

    .navbar+.item-help{
      position: absolute;
      z-index: 10;
      bottom: -60px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 50px;
      width: 50px;
      overflow: hidden;
      right: 5px;
      border-radius: 25px;
      transition: all .3s ease-in-out;

      @if $view == "light" {
        @include box-shadow();
        background-color: $light-white;
        a{
          color:$text-color;
        }
      }@else{
        background-color: $light-blue;
        a{
          color:white;
        }
      }

      a{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        padding: 0 $margin-sm;
        transition: all .3s ease-in-out;

        svg *{
          transition: all .3s ease-in-out;
        }

        span{
          margin-left: $margin-sm;
        }

        &:hover{
          color : $primary-color;
          svg *{
            fill : $primary-color !important;
          }
        }

      }
    }

    .navbar.nearby+.item-help{
      width: 150px;
      box-sizing: border-box;
    }

    a.active{
      color : $primary-color !important;
      &:before{
        right:0 !important;
      }
      svg *{
        fill : $primary-color !important;
      }
    }

  }

}

/////// DROPDOWN ////////

@mixin dropdown(){

  .dropdown-container{
    position: relative;
    font-family: 'Montserrat-SemiBold';
    font-size: $regular;
    display: inline-block;
    // width: 25%;
    // max-width: 340px;
    height: calc(#{$item-dropdown-height} + 3px );
    box-sizing: border-box;
    padding: 0;


    .dropdown-title{
      display: flex;
      width: 100%;
      height: $item-dropdown-height;
      padding: 0 $margin-xs 0 20px;
      box-sizing: border-box;
      cursor: pointer;
      // text-transform: capitalize;
      align-items: center;
      p{
        margin: 0;
        padding: 0;
        white-space: normal;
        text-align: left;
        line-height: 16px;
      }

      >div{
        height: 16px;
      }

      .indicator{
        // position: absolute;
        display: block;
        line-height: 20px;
        text-align: center;
        min-height: 20px;
        min-width: 20px;
        border-radius: 10px;
        color: white;
        font-size: $small;
        margin-right: 10px;
        background-color: $primary-color;
      }


    }

    i{
      margin: 0 3px;
      top:0;
      right:5px;
      line-height: 16px;
      color:$text-color;
    }

  }

  .dropdown-container .dropdown{
    position: absolute;
    display: none;
    top: 58px;
    bottom: 0;
    z-index: 100;
    background-color: $light-grey;
    // max-width: 320px;
    // max-height: 420px;
    padding-top: 4px;
    padding-right: -20px !important;
    border-radius: 0 0 5px 0;
    overflow-y:auto;
    overflow-x: hidden;

    flex-direction: row;
    // border-bottom: solid 2px $disabled-blue;
    // box-shadow: 2px 2px 10px 0 rgba(0,0,0,.2);


    &:before{
      content : '';
      position: absolute;
      top:0px;
      left:0;
      width: 100%;
      height: 4px;
      background-color: $primary-color;
      // background-color: $disabled-blue;
    }

    ul{
      // width: 324px;
      // column-count: 3;
      // max-height: 400px;
      padding: 0 $margin-sm;
      // overflow-y:auto;
      margin-top: 0;

      .step-refine{
        margin: 0;
        color: $blue;
      }

    }

    .clear{
      color: $primary-color;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
      svg {
        margin-right: 10px;
      }
    }

    li{
      display: flex;
      align-items: flex-start;
      padding: 10px 0;
      list-style-type: none;
      cursor: pointer;
      overflow: hidden;
      label{
        max-width: 350px;
        line-height: 18px;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
        // text-transform: capitalize;
      }
      img{
        margin-right: 10px;
      }

      img:hover+label{
        text-decoration: underline;
      }

    }
  }

}

/////// DROPDOWN ////////

@mixin GraphRange(){

  .dataRange{
    pointer-events: none;
  }

  .overlay-left{
    position: absolute;
    top:0;
    bottom: 0;
    left: 107px;
    opacity: .7;
    width: 100px;
    // height: $footer-height;
    background-color: $light-grey;
    transition: all .3s ease-out;
    pointer-events: none;
    &:before{
      position: absolute;
      content : "";
      height: $footer-height;
      width: 100px;
      background-color: $light-grey;
      left:-100px;
    }
  }

  .overlay-right{
    position: absolute;
    top:0;
    right: 60px;
    opacity: .7;
    width: 100px;
    height: $footer-height;
    background-color: $light-grey;
    transition: all .3s ease-out;
    pointer-events: none;
    &:before{
      position: absolute;
      content : "";
      height: $footer-height;
      width: 70px;
      background-color: $light-grey;
    }
  }

  .container-input-range.disabled{
    pointer-events: none;
    .input-range__slider{
      background-color: $md-grey !important;
      box-shadow: 0 4px 12px 0 rgba(253,253,255,.3);
    }
    .input-range__track .input-range__track--active{
      background-color: $md-grey !important;
    }
  }

  .input-range{
    position: relative;
    top:-6px;
    background-color: transparent;
    box-sizing: border-box;
    width: calc(100% - 40px);
    // width: 100%;
    margin: 0 auto;
  }

  .input-range__track{
    transition: left 0.1s ease-out, width 0.1s ease-out !important;
  }

  .input-range__slider-container{
    transition: left 0.1s ease-out !important;
  }

  //
  // .input-range__slider{
  //   transition: transform 0.1s ease-out !important;
  // }

  .input-range__slider{
    background-color: $primary-color;
    box-shadow: 0 4px 12px 0 rgba(208,2,37,.3);
    border: none;
  }

  .input-range__track .input-range__track--active{
    background-color: #FFB8AC;
  }

  .input-range__label.input-range__label--min,
  .input-range__label.input-range__label--max{
    display: none;
  }

  .container-input-range:not(.label-below){
    .input-range__label.input-range__label--value{
      top: -4.3rem;
      opacity: 1;
      &:after{
        position: absolute;
        z-index: -2;
        content : "";
        height: 40px;
        width: 1px;
        left:50%;
        top:24px;
        background-color: $primary-color;
        transform: translateX(-50%);
      }
    }
  }

  .container-input-range.label-below{
    padding-bottom: 40px;
    .input-range__label.input-range__label--value{
      top: 1rem;
    }
  }

  .input-range__label.input-range__label--value{
    pointer-events: none;
    z-index: 1;
    font-family: "Montserrat-SemiBold";
    background-color: white;
    transform: translateX(-50%);
    padding: 5px 12px;
    border-radius: 12px;
    color:$text-color;

    >.input-range__label-container{
      left:0;
    }

  }

}


//// DROPDOWN filtersReducer

@mixin dropdownFilter(){
  .filters-list{
    position: relative;
    display: flex;
    p{
      display: flex;
      align-items: center;
      justify-content: space-between;
      // width: calc(#{$margin-md} + 320px);
      font-size: $regular;
      margin : 0;
      box-sizing: border-box;
      // height: 30px;
      cursor: pointer;
      @include noselect();
      i{
        vertical-align: middle;
        height: 18px;
        width: 18px;
        @media screen and (max-width: $sm-screen){
          transform-origin: center;
          transform: scale(1.4);
        }
      }
      svg{
        position: relative;
        top:1px;
        margin: 0 $margin-md 0 5px;
        width: 12px;
        height: 12px;
      }
      // &.open i {
      //   color : $primary-color;
      //   transform: rotate(180deg);
      // }
      b{
        display: inline-block;
        margin-left: 3px;
      }
    }

    .select{
      position: absolute;
      z-index: 10;
      top:42px !important;
      left: 0;

      background-color: $light-grey;
      border: solid 1px $md-grey;
      border-radius: 4px;
      box-shadow: 2px 2px 10px 0 rgba(0,0,0,.1);

      ul{
        width: 320px;
        padding: 10px $margin-sm;
        margin: 0;
        li{
          list-style-type: none;
          font-size: $regular;
          line-height: calc(#{$regular} + 8px);
          cursor: pointer;

          svg{
            position: relative;
            top:1px;
            margin: 0 $margin-md 0 5px;
            width: 12px;
            height: 12px;
          }

          &:hover{
            font-weight: bold;
          }
        }
      }
    }

  }
}


/// NAVIGATION DOTS

@mixin navigationDots(){
  .navigation-dots{
    position: fixed;
    left:50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    z-index: 100;
    bottom: 30px;
    max-width: 90%;

    background-color: rgba(29, 34, 97, .5);
    border-radius: 8px;

    >div{
      padding: 14px;
    }

    .dot{
      width: 16px;
      height: 16px;
      border-radius: 8px;
      background-color: $disabled-blue;
      &.active{
        background-color: $primary-color;
      }
    }
  }
}

//CLOSE

@mixin close(){
  .close{
    position:relative;
    width: 25px;
    height: 25px;
    transform: scale(.8);
    cursor: pointer;
    &:after,&:before{
      content: "";
      position: absolute;
      display: block;
      width: 26px;
      height: 3px;
      top:50%;
      border-radius: 2px;
      background-color: $primary-color;
      transform-origin: center;
    }

    &:after{
      transform: translate(0,-50%) rotate(45deg);
    }

    &:before{
      transform: translate(0,-50%) rotate(-45deg);
    }



  }
}


//SHADOW

@mixin box-shadow(){
  box-shadow: 0px 3px 15px rgba(155, 165, 185, 0.44);
  // box-shadow: 0px 3px 15px rgba(21, 24, 69, 0.50);
}

@mixin box-shadow2($mode: blue){
  @if $mode == light {
    box-shadow: 0px 1px 17px rgba(186, 186, 225, 0.56);
  } @else {
    box-shadow: 0px 1px 17px rgba(20, 20, 61, 0.8);
  }
}

