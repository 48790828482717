@import '../../../scss/mixins.scss';

@mixin block-title($mode: blue){
  .block-title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 $margin-sm 0;
      h3{
        font-family: "Montserrat-SemiBold";
        font-size: $medium;
        // color : $blue;
        @if $mode == light {
          color : $blue;
        } @else {
          color : $white;
        }
        margin: 0;
      }


      
      @include link-entry
    }
}

@mixin link-entry{
  .link-entry{
    // text-align: left;
    p{
      display: inline-block;
      font-family: "Montserrat-Regular";
      color: $primary-color;
      margin: 0;
      cursor: pointer;
      background: rgba(27, 27, 156, 0.1);
      padding: 8px 15px;
      box-sizing: border-box;
      height: 34px;
      border-radius: 17px;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}