// FONTS SIZE

$small : 12px;
$regular : 14px;
$medium : 18px;
$title : 21px;
$titleH1 : 30px;

// METRICS
$margin-xs : 10px;
$margin-sm : 15px;
$margin-md : 30px;
$margin-lg : 45px;

$filter-width : 300px;
$advanced-filter-width : 440px;

// COLORS
$select-color : #B6BDFA;
$cumul-color : #AA7FD5;
$exclude-color : #FF7D67;
$rub-color : #f6ce3c;

$primary-color : #FF7D67;
$text-color : #151845;
$light-blue : #1D2261;
$light-white : #F0F1F4;
$blue : #151845;
$disabled-blue : #6E708C;
$md-grey: #D0D1DA;
$light-grey : #F5F5F9;
$pure-white : #ffffff;
$white : #F5F5F8;



// SCREEN WIDTH

$xs-screen : 360px;
$sm-screen : 867px;
$md-screen : 1165px;
$lg-screen : 1265px;

// FONTS

// @font-face {
// 	font-family: "Montserrat-SemiBold";
// 	src: url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
// }

// @font-face {
// 	font-family: "Montserrat-Bold";
// 	src: url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
// }

@font-face {
	font-family: "Montserrat-SemiBold";
	src: url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Medium";
	src: url("../assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Regular";
	src: url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Light";
	src: url("../assets/fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
	font-family: "Montserrat-Bold";
	src: url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

// COMPONENTS

$header-height : 50px;
$footer-height : 90px;
$item-dropdown-height: 45px;
