.exploration.light .analysis-tools-overlay-loading{
  background-color: rgba(255, 255, 255, 0.85);
}

.exploration:not(.light) .analysis-tools-overlay-loading{
  background-color: $blue;
  opacity: 0.85;
}

.exploration.light .analysis-tools{
  h3,
  .title,
  .filter,
  .filter-hover{
    color : $blue;
  }
  
  .page-tools{

    h3 a, h3 i{
      color : $blue;
    }

    .tools-list{
      ul>li>div{
        color : $blue;
      }
    }

    label{
      color : $blue;
    }
  }

  .navigation-dots{
    background-color: rgba(208,209,218,.3)
  }

  .container-search .global-search .search-field>div{
    background-color: $white;
  }

}

.exploration:not(.light) .analysis-tools{
  h3,
  .title,
  .filter,
  .filter-hover{
    color : $white;
  }


  .page-tools{

    h3 a, h3 i{
      color : $white;
    }

    .tools-list{
      ul>li>div{
        color : $white;
      }
    }

    label{
      color : white;
    }

  }

  .container-search .global-search .search-field>div{
    background-color: $blue;
  }

}

@media screen and (min-width: #{$md-screen}){
  .analysis-tools.filters-opened .overlay-loading{
    left : $advanced-filter-width;
  }
}

.overlay-loading{
  position: absolute;
  left:0;
  right:0;
  top: 0;
  bottom: 0;
  opacity: 0.85;
  display: flex;
  align-items: center;
  justify-content: center;

  img{
    opacity: 0.5;
    width: 35px;
  }

}

.analysis-tools-overlay-loading{
  position: absolute;
  z-index: 101;
  top: 0;
  bottom: 0;
  background-color: $blue;
  opacity: 0.85;
  // border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img{
    opacity: 0.5;
    width: 35px;
  }

}

.analysis-tools{
  position: relative;
  overflow-y: auto;
  box-sizing: border-box;
  // padding-left: 0 !important;

  h3{
    text-align: center;
    margin: $margin-md auto 0 auto;
    padding: 0 $margin-sm;
    >span{
      color: $primary-color;
    }
  }

  .container-search{
    @include globalSearch();
    @include topbarView();

    // +.page-tools{
    //     padding-top: 65px;
    // }

  }

}

// PAGE WITH ALL TOOLS
.analysis-tools .container-tools{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: calc(100% - 2*#{$margin-md});
  padding-bottom: $margin-md !important;
  margin: 0 auto;

  @media screen and (min-width: #{$md-screen}){
    max-width: 1200px;
  }

  .grid{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .title{
      width: calc(80% - 2*#{$margin-sm});
    }
    .container-item{
      width: 100%;
      margin: 0 $margin-sm;

      @media screen and (min-width: 500px){
        width: calc(80% - 2*#{$margin-sm});
        max-width: 400px;
      }


      @media screen and (min-width: #{$sm-screen}){
        width: calc(50% - 2*#{$margin-sm});
        max-width: inherit;
      }

      @media screen and (min-width: #{$md-screen}){
        width: calc(40% - 2*#{$margin-sm});
      }

    }
  }

  .column{
    min-width: calc(25% - 2*#{$margin-md});
    max-width: calc(60% - 2*#{$margin-sm});
  }


  .grid,
  .column{
    flex: 1;
    padding: $margin-md $margin-md 0 $margin-md;
    .title{
      font-family: 'Montserrat-SemiBold';
      text-transform: uppercase;
      font-size: $medium;
      text-align: center;
      padding-bottom: $margin-sm;
      border-bottom: solid 1px $disabled-blue;
      color : $primary-color;
    }


    .container-item:last-child .item{
      margin-bottom: 0;
    }

    .item{
      position: relative;
      margin-bottom: $margin-md;
      cursor: pointer;
      overflow: hidden;

      .filter-hover,
      .filter{
        font-size: $regular;
        transition: all .4s ease-in-out;
        margin-top: 0;
      }

      &.square{
        .image{
          position: relative;
          padding-top: 100%;
          height: 0;
          img{
            position: absolute;
            top:50%;
            left:50%;
            object-fit: cover;
            transform: translate(-50%,-50%);
            min-height: 100%;
          }
        }
      }

      .filter-hover{
        position: absolute;
        z-index: 1;
        top:0;
        opacity: 0;
        color: $primary-color;
        transform: translateY(20px);
      }

      .description{
        position: absolute;
        bottom: 0;
        height: 120px;
        min-width: 100%;
        background-color: $white;
        transform: translateY(100%);
        transition: all .4s ease-in-out;
        box-shadow: inset 0px 0px 0px 1px #E5E5E5;
        p{
          color : $text-color;
          font-size: $regular;
          padding: $margin-sm;
          margin: 0;
        }
      }

      &:hover{
        .filter-hover{
          opacity: 1;
          transform: translateY(0px);
        }

        .filter{
          opacity: 0;
          transform: translateY(-20px);
        }

        .description{
          transform: translateY(0%);
        }

        .see-ressource,
        .download-file{
          bottom: 5px;
        }

      }
        
      .see-ressource,
      .download-file{
        position: absolute;
        z-index: 2;
        bottom: 5px;
        margin:0;

        @media screen and (min-width: #{$md-screen}){
          bottom: -100px;
        }

        &.touch-screen{
          bottom: 5px;
        }


        display: flex;
        align-items: center;
        justify-content: center;
        width:24px;
        height: 24px;
        padding: 8px;
        color : $blue;
        font-size: 22px;
        background-color: $light-grey;
        border-radius: 100%;
        box-shadow: inset 0px 0px 0px 1px #E5E5E5;
        transition: all .4s ease-in-out;

        &:hover{
          box-shadow: inset 0px 0px 0px 1px $disabled-blue;
        }

      }

      .see-ressource{
        right:5px;
      }

      .download-file{
        right:50px;
      }


      .image{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $light-blue;
        height: 120px;
        width: 100%;
        // border: solid 3px $white;
        // box-shadow: 0px 0px 0px 3px $white inset;
        overflow: hidden;

        img{
          object-fit: cover;
          width: 100%;
          max-height: 100%;
        }
      }

    }

  }
}

.analysis-tools.filters-opened .page-tools .mansory{
  grid-template-columns: repeat( auto-fill, minmax( 100%, 1fr ) ); /* [2] Make columns adjust according to the available viewport */

  @include breakpoint(xs) {
    grid-template-columns: repeat( auto-fill, minmax( 31%, 1fr ) ); /* [2] Make columns adjust according to the available viewport */

  }

  @include breakpoint(sm) {
    grid-template-columns: repeat( auto-fill, minmax( 31%, 1fr ) ); /* [2] Make columns adjust according to the available viewport */

  }

  @include breakpoint(md) {
    grid-template-columns: repeat( auto-fill, minmax( 25%, 1fr ) ); /* [2] Make columns adjust according to the available viewport */
  }

  @media screen and (min-width: 1165px){
    grid-template-columns: repeat( auto-fill, minmax( 40%, 1fr ) ); /* [2] Make columns adjust according to the available viewport */

  }

  @include breakpoint(xl) {
    grid-template-columns: repeat( auto-fill, minmax( 25%, 1fr ) ); /* [2] Make columns adjust according to the available viewport */
  }
}

// PAGE TOOLS
.analysis-tools .page-tools{
  padding-bottom: 110px;

  @media screen and (min-width: #{$sm-screen}){
    padding-bottom: 0;
  }

  >h3{
    font-family: 'Montserrat-SemiBold';
    font-size: 25px;
    text-align: left;
    margin: 25px 0;
    padding: 0 $margin-sm 0 0px;

    @media screen and (min-width: 500px){
      padding: 0 $margin-sm 0 25px;
    }

    a{
      text-decoration: none;
    }
    i{
      position: relative;
      top:4px;
      margin-left: $margin-xs;
      margin-right: $margin-xs;
    }
  }

  @include navigationDots();

  .navigation{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0 $margin-sm $margin-sm 0;
    margin: 0;

    @media screen and (min-width: 500px){
      padding: 0 $margin-sm $margin-sm 25px;
    }

    @media screen and (min-width: #{$md-screen}){
      justify-content: center;
      padding: $margin-md $margin-sm $margin-sm 25px;
    }

    li{
      display: inline-block;
      list-style-type: none;
      padding:  5px $margin-sm;


      a{
        font-family: 'Montserrat-SemiBold';
        text-transform: uppercase;
        text-decoration: none;
        color : $disabled-blue;
        white-space: nowrap;
        &.active{
          color : $primary-color;
        }
      }
    }
  }

  .mansory{
    display: grid;
    grid-gap: 14px; /* [1] Add some gap between rows and columns */
    grid-template-columns: repeat( auto-fill, minmax( 100%, 1fr ) ); /* [2] Make columns adjust according to the available viewport */
    grid-auto-rows: 0;
    max-width: 1250px;
    margin: 0 auto;

    @include breakpoint(xs) {
      grid-template-columns: repeat( auto-fill, minmax( calc(50% - 28px), 1fr ) ); /* [2] Make columns adjust according to the available viewport */
    }

    @media screen and (min-width: 960px){
      grid-template-columns: repeat( auto-fill, minmax( calc(33% - 28px), 1fr ) ); /* [2] Make columns adjust according to the available viewport */
    }

    @media screen and (min-width: 1165px){
      grid-template-columns: repeat( auto-fill, minmax( calc(25% - 28px), 1fr ) ); /* [2] Make columns adjust according to the available viewport */
    }

  }

  .table-tools{
    padding: 0 5px;

    @media screen and (min-width: 500px){
      padding: 0 $margin-md;
    }

    .tools-list{
      font-family: 'Montserrat-SemiBold';
      vertical-align: top;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;

      // -webkit-box-shadow: 0px 0px 0px 3px white;
      // -moz-box-shadow: 0px 0px 0px 3px white;
      // box-shadow: 0px 0px 0px 3px white;

      .content{
        box-sizing: border-box;
        padding: 0 $margin-xs;

      }

      img{
        width: 100%;
        margin-top: $margin-sm;
      }


      // &:nth-child(odd){
      //   margin: 0 -1px
      // }

      label{
        display: block;
        border-bottom: solid 1px $white;
        padding-bottom: 5px;
        width: 100%;
      }

      ul{
        padding: 0;
        margin: $margin-sm 0 0 0;
      }

      li{
        list-style-type: none;
        margin: 5px 0;

        &.disabled{
          opacity: .2;
        }

        &:last-child{
          margin-bottom: 0;
        }
        >div{
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px;
          font-size: $small;
          border-radius: 5px;
          cursor: pointer;

          p{
            margin: 0;
            padding-right: 3px;
          }

          >span{
            background-color: $primary-color;
            border-radius: 5px;
            padding: 3px 5px;
            color: $white;
          }
        }
      }

    }

  }

}
